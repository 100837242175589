import { SET_PO_FILTERS, SET_ALL_FILTERS, CLEAR_PO_FILTERS, CLEAR_ONE_FILTER } from 'actions/PoListActions';
import FilterUtilities from '../data/FilterUtilities';
import {
    SET_SAVED_FILTERS_AND_GRID_SETTINGS,
    HIDE_SAVED_FILTERS_ICON,
    SET_GRID_SETTINGS_ORDER,
    SET_GRID_COLUMN_TOGGLE,
    TOGGLE_COLUMN_LIST,
    TOGGLE_GRID_SETTINGS,
    RESET_GRID
} from '../actions/actions';

const initialState = {
    selectedFilters: FilterUtilities.DefaultFilters,
    gridColumns: FilterUtilities.DefaultGridColumns,
    defaultGridColumns: FilterUtilities.DefaultGridColumns,
    showFilterControl: false,
    showColumnBar: false,
    showGridSettings: false,
    filterResult: []
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GRID_SETTINGS_ORDER:
            const gridColumnsCopy = [...state.gridColumns];
            const { columnSourceIndex, columnTargetIndex } = action.payload;
            gridColumnsCopy.splice(columnTargetIndex, 0, gridColumnsCopy.splice(columnSourceIndex, 1)[0]);

            return {
                ...state,
                gridColumns: gridColumnsCopy
            };

        case SET_GRID_COLUMN_TOGGLE:
            const { targetColumn } = action.payload;
            const newGridColumns = state.gridColumns.map((column) => {
                const columnKey = Object.keys(column)[0];
                const newColumnArray = [...column[columnKey]];
                const currentStatus = column[columnKey][3];
                if (columnKey === targetColumn) newColumnArray[3] = !currentStatus;
                return { [columnKey]: [...newColumnArray] };
            });

            return {
                ...state,
                gridColumns: newGridColumns
            };

        case TOGGLE_COLUMN_LIST:
            return { ...state, showColumnBar: action.payload };

        case TOGGLE_GRID_SETTINGS:
            return { ...state, showGridSettings: action.payload };

        case RESET_GRID:
            return { ...state, gridColumns: JSON.parse(JSON.stringify(initialState))['gridColumns'] };

        case HIDE_SAVED_FILTERS_ICON:
            if (action.payload === true) {
                return { ...state, showFilterControl: false };
            } else if (action.payload === false) {
                return { ...state, showFilterControl: true };
            }
            return state;

        case SET_SAVED_FILTERS_AND_GRID_SETTINGS:
            if (!action.payload) {
                return state;
            } else {
                const gridColumns =
                    action.payload &&
                    action.payload.length > 0 &&
                    'GridSetting' in action.payload[0] &&
                    [null, 'null', undefined, 'undefined', ''].indexOf(action.payload[0].GridSetting) < 0
                        ? JSON.parse(action.payload[0].GridSetting)
                        : JSON.parse(JSON.stringify(state))['gridColumns'];
                return {
                    ...state,
                    filterResult: action.payload && action.payload.length > 0 ? action.payload[0] : [],
                    gridColumns,
                    defaultGridSettings: gridColumns
                };
            }

        case SET_PO_FILTERS:
            if (
                state.selectedFilters[action.payload.name] == null ||
                state.selectedFilters[action.payload.name].value !== action.payload.option.value ||
                state.selectedFilters[action.payload.name].isSet !== action.payload.option.isSet
            ) {
                return {
                    ...state,
                    selectedFilters: {
                        ...state.selectedFilters,
                        [action.payload.name]: action.payload.option
                    }
                };
            }
            return state;
        case SET_ALL_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload.filters
            };
        case CLEAR_PO_FILTERS:
            return {
                ...state,
                selectedFilters: FilterUtilities.EmptyFilters
            };
        case CLEAR_ONE_FILTER:
            var selectedFilters = {};

            Object.entries(state.selectedFilters).forEach((filter) => {
                if (state.selectedFilters[action.payload.filter].index < filter[1].index) {
                    selectedFilters[filter[0]] = { ...filter[1], index: filter[1].index - 1 };
                } else {
                    selectedFilters[filter[0]] = filter[1];
                }
            });

            return {
                ...state,
                selectedFilters: {
                    ...selectedFilters,
                    [action.payload.filter]: FilterUtilities.EmptyFilters[action.payload.filter]
                }
            };
        default:
            return state;
    }
};

export default filterReducer;
