import { Middleware } from 'one-ring';

const salesTypesMappings = [
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('SalesType', 'SalesType'),
    Middleware.CreateMapping('GroupId', 'GroupId'),
    Middleware.CreateMapping('Name', 'Name'),
    Middleware.CreateArrayMapping('Results') //array name change
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('salesTypes', 'GET')) {
        Middleware.RegisterMapping('salesTypes', 'GET', salesTypesMappings);
    }
}

const salesTypes = {
    ConfigureMiddleware
};

export default salesTypes;
