import { Middleware } from 'one-ring';

const paymentMappings = [
    Middleware.CreateMapping('ID', 'ID'),
    Middleware.CreateMapping('Message', 'Message'),
    Middleware.CreateMapping('message', 'message'),
    Middleware.CreateMapping('PONbr', 'PONbr'),
    Middleware.CreateMapping('SONbr', 'SONbr'),
    Middleware.CreateMapping('InvoiceAccountNumber', 'BillToNbr'),
    Middleware.CreateMapping('InvoiceAccountName', 'BillToName'),
    Middleware.CreateMapping('PaymentDate', 'PaymentDate'),
    Middleware.CreateMapping('InvoiceNbr', 'InvoiceNbr'),
    Middleware.CreateMapping('PayerName', 'PayerName'),
    Middleware.CreateMapping('PaymentReference', 'PaymentReference'),
    Middleware.CreateMapping('TransControlNbr', 'TransControlNbr'),
    Middleware.CreateMapping('CurrencyCode', 'CurrencyCode'),
    Middleware.CreateMapping('TransactionType', 'TransactionType'),
    Middleware.CreateMapping('InvoiceAmount', 'InvoiceAmount'),
    Middleware.CreateMapping('PaymentAmount', 'PaymentAmount'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('BillToName', 'BillToName'),
    Middleware.CreateMapping('BillToNumber', 'BillToNumber'),
    Middleware.CreateMapping('BillToNbr', 'BillToNbr'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('PayeeName', 'PayeeName'),
    Middleware.CreateMapping('PayerID', 'PayerID'),
    Middleware.CreateMapping('Header', 'Header'),
    Middleware.CreateMapping('Lines', 'Lines'),
    Middleware.CreateMapping('ToPaymentDate', 'ToPaymentDate'),
    Middleware.CreateMapping('FromPaymentDate', 'FromPaymentDate'),
    Middleware.CreateMapping('PaymentID', 'PaymentID'),
    Middleware.CreateMapping('ShipToNbr', 'ShipToNbr'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('PaymentMethod', 'PaymentMethod'),
    Middleware.CreateMapping('InvoiceDate', 'InvoiceDate'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('payments', 'payments'),
    Middleware.CreateMapping('TotalCount', 'TotalCount'),
    Middleware.CreateMapping('Notes', 'Notes'),
    Middleware.CreateMapping('TransType', 'TransType'),
    Middleware.CreateMapping('BatchNumber', 'BatchNumber'),
    Middleware.CreateMapping('CreditMemo', 'CreditMemo'),
    Middleware.CreateMapping('PaymentJournal', 'PaymentJournal'),
    Middleware.CreateMapping('PaymentLineID', 'PaymentLineID'),
    Middleware.CreateMapping('Response', 'Response'),
    Middleware.CreateArrayMapping('payments') //array name change
];
function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Payment', 'GET')) {
        Middleware.RegisterMapping('Payment', 'GET', paymentMappings);
        Middleware.RegisterMapping('Payment', 'POST', paymentMappings);
        Middleware.RegisterMapping('Payment', 'DELETE', paymentMappings);
    }
}

const payment = {
    ConfigureMiddleware
};

export default payment;
