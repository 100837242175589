//BEGIN - SO Conversion Page

export const SET_SO_CONVERSION_DETAILS = 'SET_SO_CONVERSION_DETAILS';
export const CLEAR_SO_CONVERSION_DETAILS = 'CLEAR_SO_CONVERSION_DETAILS'
export const setSOConversionDetails = (soConversionData) => ({
    type: SET_SO_CONVERSION_DETAILS,
    payload: {soConversionData}
});
export const clearSOConversionDetails = () => ({
    type: CLEAR_SO_CONVERSION_DETAILS,
    payload: {}
})
//END - SO Conversion Page
