const defaultFilters = {
    Status: { value: '', label: '', index: -1, isSet: false },
    InvoiceAccountNumber: { value: '', label: '', index: -2, isSet: false },
    ModeOfDelivery: { value: '', label: '', index: -3, isSet: false },
    ShipmentRequested: { value: '', label: '', index: -4, isSet: false },
    DeliveryRequested: { value: '', label: '', index: -5, isSet: false },
    RWH: { value: '', label: '', index: -6, isSet: false },
    BerryType: { value: '', label: '', index: -7, isSet: false },
    Warehouse: { value: '', label: '', index: -8, isSet: false },
    TypeOfChange: { value: '', label: '', index: -10, isSet: false },
    Channel: { value: '', label: '', index: -11, isSet: false },
    SalesRep: { value: '', label: '', index: -12, isSet: false },
    EmployeeResponsible: { value: '', label: '', index: -13, isSet: false },
    PoNumber: { value: '', label: '', index: -14, isSet: false },
    ShipTo: { value: '', label: '', index: -15, isSet: false },
    BerryFieldType: { value: '', label: '', index: -16, isSet: false },
    Poolweek: { value: '', label: '', index: -17, isSet: false },
    POType: { value: 'OpenPos', label: 'Open POs', index: 0, isSet: true },
    ItemNbr: { value: '', label: '', index: -18, isSet: false }
};
const paymentFilters = {
    PaymentReference: { value: '', label: '', index: -1, isSet: false },
    PaymentDate: { value: '', label: '', index: -2, isSet: false },
    PaymentMethod: { value: '', label: '', index: -3, isSet: false },
    CheckNumber: { value: '', label: '', index: -4, isSet: false },
    InvoiceAccountName: { value: '', label: '', index: -5, isSet: false },
    InvoiceAccountNumber: { value: '', label: '', index: -6, isSet: false },
    InvoiceNbr: { value: '', label: '', index: -7, isSet: false },
    Status: { value: 'Created', label: 'Created', index: -8, isSet: true } //defaulting status filter to Created
};
const emptyPaymentFilters = {
    PaymentReference: { value: '', label: '', index: -1, isSet: false },
    PaymentDate: { value: '', label: '', index: -2, isSet: false },
    PaymentMethod: { value: '', label: '', index: -3, isSet: false },
    CheckNumber: { value: '', label: '', index: -4, isSet: false },
    InvoiceAccountName: { value: '', label: '', index: -5, isSet: false },
    InvoiceAccountNumber: { value: '', label: '', index: -6, isSet: false },
    InvoiceNbr: { value: '', label: '', index: -7, isSet: false },
    Status: { value: '', label: '', index: -8, isSet: false }
};

const emptyFilters = {
    InvoiceAccountNumber: { value: '', label: '', index: -2, isSet: false },
    ModeOfDelivery: { value: '', label: '', index: -3, isSet: false },
    ShipmentRequested: { value: '', label: '', index: -4, isSet: false },
    DeliveryRequested: { value: '', label: '', index: -5, isSet: false },
    RWH: { value: '', label: '', index: -6, isSet: false },
    BerryType: { value: '', label: '', index: -7, isSet: false },
    Warehouse: { value: '', label: '', index: -8, isSet: false },
    Status: { value: '', label: '', index: -9, isSet: false },
    TypeOfChange: { value: '', label: '', index: -10, isSet: false },
    Channel: { value: '', label: '', index: -11, isSet: false },
    SalesRep: { value: '', label: '', index: -12, isSet: false },
    EmployeeResponsible: { value: '', label: '', index: -13, isSet: false },
    PoNumber: { value: '', label: '', index: -14, isSet: false },
    ShipTo: { value: '', label: '', index: -15, isSet: false },
    BerryFieldType: { value: '', label: '', index: -16, isSet: false },
    Poolweek: { value: '', label: '', index: -17, isSet: false },
    POType: { value: '', label: '', index: -18, isSet: false },
    ItemNbr: { value: '', label: '', index: -18, isSet: false }
};
// We need to update order too thats why using array
const defaultGridColumns = [
    { SoNumber: ['Sales Order Number', 'SO', 90, true] },
    { InvoiceAccount: ['Invoice Account Number', 'Invoice Account', 120, true] },
    { InvoiceAccountName: ['Invoice Account Name', 'Invoice Account Name', 140, true] },
    { InvoiceNumber: ['Invoice Number', 'Invoice Number', 140, true] },
    { ShipTo: ['Ship to Invoice Account Number', 'Ship to Account', 140, true] },
    { ShipToName: ['Ship to Account Name', 'Ship to Account Name', 110, true] },
    { OcNumber: ['OcNumber', 'OC', 95, true] },
    { Warehouse: ["Driscoll's Warehouse", 'Warehouse', 80, true] },
    { PaymentTerms: ['Payment Terms', 'Payment Terms', 80, true] },
    { Date: ['PO Date', 'PO Date', 90, true] },
    //OBO Might go here, need to see if that is true with Chinmoy
    { Status: ['Status', 'Status', 85, true] },
    { BerryTypeString: ['Berry', 'Berry', 95, true] },
    { ModeOfDelivery: ['Mode of Delivery', 'Dlv Mode', 100, true] },
    { DeliveryTerms: ['Delivery Terms', 'Delivery Terms', 110, true] },
    { Channel: ['Channel', 'Channel', 80, true] },
    { EmployeeResponsible: ['Employee Responsible', 'Employee Responsible', 140, true] },
    { SalesRep: ['Sales Taker', 'Sales Taker', 110, true] },
    { TypeOfChange: ['Change Type - Coming soon to a theater to you! (not yet implemented)', 'Change Type', 110, true] },
    { FSD: ['', 'FSD', 110, true] },
    { RWH: ['', 'RWH', 130, true] },
    { ShipmentRequestedString: ['Shipment Requested Date', 'Shipment Requested Date', 100, true] },
    { DeliveryRequestedString: ['Requested Receipt Date', 'Requested Receipt Date', 100, true] },
    { CreatedDateTimeString: ['Receipt Date Time', 'Receipt Date Time', 140, true] },
    { PoDateTimeString: ['Confirmation Date Time', 'Confirmation Date Time', 140, true] }
];

const berryFieldTypeOptions = [
    { value: 'CONVENTIONAL', label: 'Conventional' },
    { value: 'ORGANIC', label: 'Organic' }
];

const POTypeOptions = [
    { value: 'OpenPos', label: 'Open POs' },
    { value: 'CustomerChanges', label: 'Changed By Customer' },
    { value: 'UnmappedPos', label: 'Unmapped POs' },
    { value: 'SalesOrders', label: 'Sales Orders' }
];

var encodeFilterUrl = (filters, history) => {
    var filterReducedObj = {};
    var filterKeys = Object.keys(filters || {});

    filterKeys
        .filter((key) => {
            return filters[key].isSet;
        })
        .forEach((key) => {
            var newValue = filters[key].value;

            filterReducedObj[key] = newValue;
        });
    if (Object.keys(filterReducedObj).length <= 0 && history) {
        history.push(`/PurchaseOrders/Search/`);
        return;
    }

    if (history) {
        history.push(createUrl(filterReducedObj));
    } else return createUrl(filterReducedObj, true);
};

var createUrl = (values, returnEncodedString = false) => {
    var necessaryFilters = {};

    Object.keys(values).forEach((key) => {
        if (values[key] && values[key] !== 'null') {
            necessaryFilters[key] = values[key];
        }
    });

    var stringified = JSON.stringify(necessaryFilters);
    var encoded = encodeURI(stringified);
    if (returnEncodedString) return btoa(encoded);
    return `/PurchaseOrders/Search/${btoa(encoded)}/`;
};

var findLabelByValuePOtype = (povalue)=>{
    let result=povalue;
    POTypeOptions.forEach((val,ind)=>{
        if(povalue?.toString()?.toLowerCase() == val.value?.toString()?.toLowerCase()){
            result = val.label;
        }
    })
    return result;
}

var decodeFilterUrl = (currentFilters, params, masterDataProperty, setAllFiltersAction, clearPoFilters) => {
    if (!masterDataProperty.isInitialized) {
        return currentFilters; //Do nothing yet, we need master data initialized before we try to load filters
    }

    if ((params.PoNumber || '').length > 0 && (params.ShipTo || '').length > 0) {
        var simpleFilters = { ...emptyFilters };

        simpleFilters.PoNumber = {
            value: params.PoNumber,
            label: params.PoNumber,
            isSet: true,
            index: 0
        };

        simpleFilters.ShipTo = {
            value: params.ShipTo,
            label: params.ShipTo,
            isSet: true,
            index: 1
        };

        setAllFiltersAction(simpleFilters);

        return simpleFilters;
    }

    var encodedCriteriaFilters = params.Criteria || '';

    if (encodedCriteriaFilters.length <= 0) {
        return currentFilters;
        //Nothing to change, keep the current filters default or otherwise
    }

    var decoded = decodeURI(atob(encodedCriteriaFilters));

    var jsonFilters = JSON.parse(decoded) || {};

    //Generate the full filter object
    var filters = { ...emptyFilters };
    var filterKeys = Object.keys(jsonFilters || {});

    const md = masterData(masterDataProperty);

    //use filter from reducer as we know its not set, make sure to set value, label, isSet, index, if not in master data assume value === label
    var hasChanges = false;
    var currentIndex = 0;

    filterKeys.forEach((key) => {
        if (filters[key].value !== jsonFilters[key] || filters[key].isSet === false) {
            var label = findLabelByValuePOtype(jsonFilters[key])
            if (label !== null) {
                if (md[key]) {
                    var record = md[key].find((data) => data.value.toString() === jsonFilters[key].toString());
                    if (record) {
                        label = record.label;
                    }
                }
                filters[key] = { ...filters[key] };
                filters[key].value = jsonFilters[key];
                filters[key].label = label;
                filters[key].isSet = true;
                filters[key].index = currentIndex;
                hasChanges = true;
            }
        }

        currentIndex++;
    });

    if (hasChanges) {
        clearPoFilters();
        setAllFiltersAction(filters);
    }

    return filters;
};

var masterData = (masterData) => {
    return {
        PaymentMethod: masterData.paymentMethodOptions,
        Status: masterData.statusOptions,
        Warehouse: masterData.warehouseOptions,
        BerryType: masterData.berryTypeOptions,
        ModeOfDelivery: masterData.deliveryModeOptions,
        InvoiceAccountNumber: masterData.customerOptions,
        RWH: masterData.receivingWarehouseOptions,
        TypeOfChange: masterData.changeTypeOptions,
        Channel: masterData.channelOptions,
        ShipTo: masterData.shipToOptions,
        SalesRep: masterData.salesRepOptions,
        EmployeeResponsible: masterData.salesReps.map((salesRep) => {
            return { value: salesRep.Name, label: (salesRep.Name || '').trim() };
        }),
        Poolweek: masterData.poolWeekOptions,
        BerryFieldType: berryFieldTypeOptions,
        POType: POTypeOptions
    };
};

const FilterUtilities = {
    paymentFilters: paymentFilters,
    DefaultFilters: defaultFilters,
    EmptyFilters: emptyFilters,
    EmptyPaymentFilters: emptyPaymentFilters,
    DefaultGridColumns: defaultGridColumns,
    MasterData: masterData,
    EncodeFilterUrl: encodeFilterUrl,
    CreateUrl: createUrl,
    DecodeFilterUrl: decodeFilterUrl
};

export default FilterUtilities;
