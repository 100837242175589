import { Middleware } from 'one-ring';

const LinkSOToPOMapping = [
    Middleware.CreateMapping('Company', 'Company'),
    Middleware.CreateMapping('PONumber', 'PONumber'),
    Middleware.CreateMapping('POHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('SONumber', 'SONumber'),
    Middleware.CreateMapping('TransactionType', 'TransactionType'),
    Middleware.CreateMapping('SalesUpdatedDateTime', 'SalesUpdatedDateTime'),
    Middleware.CreateMapping('SalesUpdatedBy', 'SalesUpdatedBy'),
    Middleware.CreateMapping('POLineId', 'POLineId'),
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('Lines', 'Lines'),
    Middleware.CreateMapping('SalesLineUpdatedDateTime', 'SalesLineUpdatedDateTime'),
    Middleware.CreateMapping('SalesLineUpdatedBy', 'SalesLineUpdatedBy'),
    Middleware.CreateMapping('ShipToNumber', 'ShipToNumber'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('LinkSOToPO', 'POST')) {
        Middleware.RegisterMapping('LinkSOToPO', 'POST', LinkSOToPOMapping, true);
    }
}

const linkSOToPO = {
    ConfigureMiddleware
};

export default linkSOToPO;
