import { Middleware } from 'one-ring';

const allowancesMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('ChargeCode', 'ChargeCode'),
    Middleware.CreateMapping('ChargeName', 'ChargeName'),
    // Middleware.CreateMapping('CustomerChargeCode', 'CustomerChargeCode'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('UpdatedBy', 'UpdatedBy'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('Enabled', 'Enabled'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Allowances', 'GET')) {
        Middleware.RegisterMapping('Allowances', 'GET', allowancesMappings);
        Middleware.RegisterMapping('Allowances', 'POST', allowancesMappings);
    }
}

const allowances = {
    ConfigureMiddleware
};

export default allowances;
