import { Middleware } from 'one-ring';

const PoListMappings = [
    Middleware.CreateIndexMapping('PoHeaderId', 'POHeaderId'),
    Middleware.CreateSortMapping('PoNumber', 'PONumber'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('NameIdentificationCode', 'NameIdentificationCode'),
    Middleware.CreateMapping('PoStageId', 'CustomerChanges'),
    Middleware.CreateMapping('OcNumber', 'OC Number'),
    Middleware.CreateMapping('SelectedQuoteNumber', 'Quote Number'),
    Middleware.CreateMapping('SoNumber', 'SO Number'),
    Middleware.CreateMapping('BerryTypes', 'BerryType'),
    Middleware.CreateMapping('PoDateTime', 'PO Confirmation Date'),
    Middleware.CreateMapping('CreatedDateTime', 'PO Receipt Date'),
    Middleware.CreateMapping('ResultCount', 'ResultCount'),
    Middleware.CreateMapping('ModeOfDelivery', 'Mode of Delivery'),
    Middleware.CreateMapping('ExternalCustomerId', 'ExternalCustomerId'),
    Middleware.CreateMapping('ExternalCustomerName', 'ExternalCustomerName'),
    Middleware.CreateMapping('ShipTo', 'ShipToNumber'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('RWH', 'ReceivingWarehouse'),
    Middleware.CreateMapping('InvoiceAccount', 'Invoice Account'),
    Middleware.CreateMapping('InvoiceAccountName', 'Invoice AccountName'),
    Middleware.CreateMapping('InvoiceNumber', 'InvoiceNumber'),
    Middleware.CreateMapping('Warehouse', 'Warehouse'),
    Middleware.CreateMapping('Channel', 'Channel'),
    Middleware.CreateMapping('ChannelId', 'ChannelId'),
    Middleware.CreateMapping('SalesRep', 'SalesTaker'),
    Middleware.CreateMapping('EmployeeResponsible', 'EmployeeResponsible'),
    Middleware.CreateMapping('SalesPerson', 'SalesPerson'),
    Middleware.CreateMapping('TypeOfChange', 'ChangeType'),
    Middleware.CreateMapping('DeliveryTerms', 'Delivery Terms'),
    Middleware.CreateMapping('PaymentTerms', 'Payment Terms'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('PoHeaderIds', 'PoHeaderIds'),
    Middleware.CreateMapping('PoNumbers', 'PoNumbers'),
    Middleware.CreateMapping('Attribute', 'Attribute'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('ShipmentRequested', 'Expected Ship Date'),
    Middleware.CreateMapping('DeliveryRequested', 'Request Receipt date'),
    Middleware.CreateMapping('quality', 'MappingLevel'),
    Middleware.CreateMapping('id', 'RefNbr'),
    Middleware.CreateMapping('Comments', 'Comments'), //This is used in a request object
    Middleware.CreateMapping('Operation', 'Operation'), //This is used in a request object
    Middleware.CreateMapping('TriggerMatchingProcess', 'TriggerMatchingProcess'),
    Middleware.CreateMapping('RecordFetchedTime', 'recordFetchedTime'),
    Middleware.CreateMapping('SalesTakerId', 'SalesTakerId'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateMapping('DriscollsOrderLock', 'DriscollsOrderLock'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('isDirected', 'isDirected'),
    Middleware.CreateArrayMapping('Results', 'Results'), //array name change
    Middleware.CreateArrayResultCountMapping('ResultCount', 'ResultCount'), //array count
    Middleware.CreateMapping('Details', 'Details'), //Only one array is supported for right now, if this is indeed needed to support multiple let Stephen know and we can look at adding support in middleware
    Middleware.CreateMapping('Date', 'Date')
];

const PoReviewListMapping = [
    Middleware.CreateIndexMapping('PoStageId', 'POStageId'),
    Middleware.CreateMapping('PoHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateSortMapping('PoNumber', 'PONumber'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('NameIdentificationCode', 'NameIdentificationCode'),
    Middleware.CreateMapping('OcNumber', 'OC Number'),
    Middleware.CreateMapping('SelectedQuoteNumber', 'Quote Number'),
    Middleware.CreateMapping('SoNumber', 'SO Number'),
    Middleware.CreateMapping('BerryTypes', 'BerryType'),
    Middleware.CreateMapping('PoDateTime', 'PO Confirmation Date'),
    Middleware.CreateMapping('CreatedDateTime', 'PO Receipt Date'),
    Middleware.CreateMapping('ResultCount', 'ResultCount'),
    Middleware.CreateMapping('ModeOfDelivery', 'Mode of Delivery'),
    Middleware.CreateMapping('ExternalCustomerId', 'ExternalCustomerId'),
    Middleware.CreateMapping('ExternalCustomerName', 'ExternalCustomerName'),
    Middleware.CreateMapping('ShipTo', 'ShipToNumber'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('RWH', 'ReceivingWarehouse'),
    Middleware.CreateMapping('InvoiceAccount', 'Invoice Account'),
    Middleware.CreateMapping('InvoiceAccountName', 'Invoice AccountName'),
    Middleware.CreateMapping('Warehouse', 'Warehouse'),
    Middleware.CreateMapping('Channel', 'Channel'),
    Middleware.CreateMapping('ChannelId', 'ChannelId'),
    Middleware.CreateMapping('SalesRep', 'SalesTaker'),
    Middleware.CreateMapping('EmployeeResponsible', 'EmployeeResponsible'),
    Middleware.CreateMapping('SalesPerson', 'SalesPerson'),
    Middleware.CreateMapping('TypeOfChange', 'ChangeType'),
    Middleware.CreateMapping('DeliveryTerms', 'Delivery Terms'),
    Middleware.CreateMapping('PaymentTerms', 'Payment Terms'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('PoHeaderIds', 'PoHeaderIds'),
    Middleware.CreateMapping('PoNumbers', 'PoNumbers'),
    Middleware.CreateMapping('Attribute', 'Attribute'),
    Middleware.CreateMapping('ShipmentRequested', 'Expected Ship Date'),
    Middleware.CreateMapping('DeliveryRequested', 'Request Receipt date'),
    Middleware.CreateMapping('quality', 'MappingLevel'),
    Middleware.CreateMapping('id', 'RefNbr'),
    Middleware.CreateMapping('Comments', 'Comments'), //This is used in a request object
    Middleware.CreateMapping('Operation', 'Operation'), //This is used in a request object
    Middleware.CreateMapping('TriggerMatchingProcess', 'TriggerMatchingProcess'),
    Middleware.CreateMapping('RecordFetchedTime', 'recordFetchedTime'),
    Middleware.CreateMapping('SalesTakerId', 'SalesTakerId'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('DriscollsOrderLock', 'DriscollsOrderLock'),
    Middleware.CreateMapping('isDirected', 'isDirected'),
    Middleware.CreateArrayMapping('Results')
];

const PoUpdateMappings = [
    Middleware.CreateIndexMapping('PoHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateMapping('ProductName', 'Description'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('PoNumber', 'PONumber'),
    Middleware.CreateMapping('BerryTypeId', 'BerryTypeId'),
    Middleware.CreateMapping('PoStageId', 'POStageId'),
    Middleware.CreateMapping('POStageLineId', 'POStageLineId'),
    Middleware.CreateMapping('NameIdentificationCode', 'NameIdentificationCode'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('POLineId', 'POLineId'),
    Middleware.CreateMapping('SoNumber', 'SONumber'),
    Middleware.CreateMapping('QuoteNbr', 'QuoteNbr'),
    Middleware.CreateMapping('ModeOfDelivery', 'ModeOfDelivery'),
    Middleware.CreateMapping('WarehouseNbr', 'WareHouseNbr'),
    Middleware.CreateMapping('Warehouse', 'Warehouse'),
    Middleware.CreateMapping('ChannelId', 'ChannelId'),
    Middleware.CreateMapping('ItemDescription', 'ItemDescription'),
    Middleware.CreateMapping('SalesPersonId', 'SalesPersonId'),
    Middleware.CreateMapping('SalesPerson', 'SalesPerson'),
    Middleware.CreateMapping('SalesTakerId', 'SalesTakerId'),
    Middleware.CreateMapping('TypeOfChange', 'TypeOfChange'),
    Middleware.CreateMapping('DeliveryTerm', 'DeliveryTerm'),
    Middleware.CreateMapping('PaymentTerms', 'DriscollsPaymentTerm'),
    Middleware.CreateMapping('PaymentTerms', 'PaymentTerms'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('DriscollsPaymentTerm', 'DriscollsPaymentTerm'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('ShipmentRequested', 'ShipmentRequested'),
    Middleware.CreateMapping('DeliveryRequested', 'DeliveryRequested'),
    Middleware.CreateMapping('id', 'RefNbr'),
    Middleware.CreateMapping('Operation', 'Operation'),
    Middleware.CreateMapping('TriggerMatchingProcess', 'TriggerMatchingProcess'),
    Middleware.CreateMapping('RecordFetchedDateTime', 'RecordFetchedDateTime'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateMapping('ItemId', 'ItemNbr'),
    Middleware.CreateMapping('CustomerItemId', 'CustomerItemId'),
    Middleware.CreateMapping('ItemStatus', 'ItemStatus'),
    Middleware.CreateMapping('Quantity', 'QuantityOrdered'),
    Middleware.CreateMapping('NewQuantity', 'DriscollsQuantity'),
    Middleware.CreateMapping('CustomerRequestedPrice', 'CustomerRequestedPrice'),
    Middleware.CreateMapping('UnitPrice', 'UnitPrice'),
    Middleware.CreateMapping('OriginalQuantity', 'DriscollsQuantity'),
    Middleware.CreateMapping('OriginalOrderQuantity', 'OriginalQuantity'),
    Middleware.CreateMapping('LoadedPrice', 'LoadedPrice'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('CancellationReason', 'CancellationReason'),
    Middleware.CreateMapping('RejectionReason', 'RejectionReason'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('ItemStatus', 'ItemStatus'),
    Middleware.CreateMapping('isDirected', 'isDirected'),
    Middleware.CreateMapping('DUNSNbr', 'DUNSNbr'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('CustomerItemId', 'CustomerItemID'),
    Middleware.CreateMapping('CustomerItemDescription', 'ItemDescription'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('IsReview', 'IsReview'),
    Middleware.CreateArrayMapping('Details', 'Details'), //Only one array is supported for right now, if this is indeed needed to support multiple let Stephen know and we can look at adding support in middleware
    Middleware.CreateMapping('isHeaderReview', 'isHeaderReview'),
    Middleware.CreateMapping('Checked', 'Checked'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('POHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateMapping('PONumber', 'PONumber'),
    Middleware.CreateMapping('POStageId', 'POStageId'),
    Middleware.CreateMapping('SONumber', 'SONumber'),
    Middleware.CreateMapping('QuoteNbr', 'QuoteNbr'),
    Middleware.CreateMapping('DeliveryRequested', 'DeliveryRequested'),
    Middleware.CreateMapping('POAssignedId', 'POAssignedId'),
    Middleware.CreateMapping('TriggerMatchingProcess', 'TriggerMatchingProcess')
];

const PoRedirectMappings = [
    Middleware.CreateIndexMapping('PoNumber', 'PONumber'),
    Middleware.CreateMapping('ShipTo', 'ShipToNumber'),
    Middleware.CreateMapping('SoNumber', 'SONumber'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('ShipmentRequested', 'ShipmentRequested'),
    Middleware.CreateMapping('isDirected', 'isDirected'),
    Middleware.CreateMapping('POHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateMapping('CustomerChanges', 'CustomerChanges'),
    Middleware.CreateArrayMapping('Results')
];

const PO_QUOTE_MATCHING = [
    Middleware.CreateIndexMapping('Id', 'Id'),
    Middleware.CreateMapping('PoNumber', 'PONbr'),
    Middleware.CreateMapping('PoHeaderId', 'POheaderId'),
    Middleware.CreateMapping('ShipTo', 'ShipToNumber'),
    Middleware.CreateMapping('MarketProgram', 'MarketProgram'),
    Middleware.CreateMapping('MatchType', 'MatchType'),
    Middleware.CreateMapping('RefId', 'RefNbr'),
    Middleware.CreateMapping('Quality', 'MappingLevel'),
    Middleware.CreateArrayMapping('Results')
];

const PoRedirectUpdateMappings = [
    Middleware.CreateMapping('NewPONumber', 'NewPONumber'), //for redirect po
    Middleware.CreateMapping('NewShipToNumber', 'NewShipToNumber'),
    Middleware.CreateMapping('NewCompanyNbr', 'NewCompanyNbr'),
    Middleware.CreateMapping('NewPOHeaderId', 'NewPOHeaderId'),
    Middleware.CreateMapping('BillToNumber', 'BillToNumber'),
    Middleware.CreateMapping('NewCreatedDateTime', 'NewCreatedDateTime'),
    Middleware.CreateMapping('OldPONumber', 'OldPONumber'),
    Middleware.CreateMapping('OldShipToNumber', 'OldShipToNumber'),
    Middleware.CreateMapping('OldCompanyNbr', 'OldCompanyNbr'),
    Middleware.CreateMapping('OldCreatedDateTime', 'OldCreatedDateTime'),
    Middleware.CreateMapping('OldPOHeaderId', 'OldPOHeaderId'),
    Middleware.CreateMapping('UpdatedDateTime', 'UpdatedDateTime')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PoList', 'GET')) {
        Middleware.RegisterMapping('PoList', 'GET', PoListMappings, true);
        Middleware.RegisterMapping('PoList', 'POST', PoListMappings);
    }

    if (!Middleware.CheckMappingExists('PoReviewListMapping', 'GET')) {
        Middleware.RegisterMapping('PoReviewListMapping', 'GET', PoReviewListMapping, true);
        Middleware.RegisterMapping('PoReviewListMapping', 'POST', PoReviewListMapping);
    }

    if (!Middleware.CheckMappingExists('PoUpdate', 'GET')) {
        Middleware.RegisterMapping('PoUpdate', 'GET', PoUpdateMappings);
        Middleware.RegisterMapping('PoUpdate', 'POST', PoUpdateMappings);
    }

    if (!Middleware.CheckMappingExists('MatchingQuotes', 'GET')) {
        Middleware.RegisterMapping('MatchingQuotes', 'GET', PO_QUOTE_MATCHING);
    }

    if (!Middleware.CheckMappingExists('PoRedirect', 'GET')) {
        Middleware.RegisterMapping('PoRedirect', 'GET', PoRedirectMappings);
    }

    if (!Middleware.CheckMappingExists('PoRedirectUpdate', 'POST')) {
        Middleware.RegisterMapping('PoRedirectUpdate', 'POST', PoRedirectUpdateMappings);
    }
}

const PoList = {
    ConfigureMiddleware
};

export default PoList;
