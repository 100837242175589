import { Middleware } from 'one-ring';

const changeTypesMappings = [
    Middleware.CreateArrayMapping('PlanGroupName', 'PlanGroupName'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('RecId', 'RecId')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('berrySalesPlanGroups', 'GET')) {
        Middleware.RegisterMapping('berrySalesPlanGroups', 'GET', changeTypesMappings);
        Middleware.RegisterMapping('berrySalesPlanGroups', 'POST', changeTypesMappings);
    }
}

const berrySalesPlanGroups = {
    ConfigureMiddleware
};

export default berrySalesPlanGroups;
