import { Middleware } from 'one-ring';

const savedFiltersMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('FilterName', 'FilterName'),
    Middleware.CreateMapping('FilterUrl', 'FilterUrl'),
    Middleware.CreateMapping('Label', 'Label'),
    Middleware.CreateMapping('isDeleted', 'isDeleted'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('Email', 'Email'),
    Middleware.CreateMapping('FilterSetting', 'FilterSetting'),
    Middleware.CreateMapping('GridSetting', 'GridSetting'),
    Middleware.CreateArrayMapping('Results')
];
function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('savedFiltersAndGridSettings', 'GET')) {
        Middleware.RegisterMapping('savedFiltersAndGridSettings', 'GET', savedFiltersMappings);
        Middleware.RegisterMapping('savedFiltersAndGridSettings', 'POST', savedFiltersMappings);
        Middleware.RegisterMapping('savedFiltersAndGridSettings', 'DELETE', savedFiltersMappings);
    }
}

const savedFilters = {
    ConfigureMiddleware
};

export default savedFilters;
