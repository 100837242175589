import { Middleware } from 'one-ring';

const ItemMappings = [
    Middleware.CreateMapping('GTIN', 'GTIN'),
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('UPCNbr', 'UPCNbr'),
    Middleware.CreateMapping('BerryTypeId', 'BerryTypeId'),
    Middleware.CreateMapping('isDeleted', 'isDeleted'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Item', 'GET')) {
        Middleware.RegisterMapping('Item', 'GET', ItemMappings);
    }
}

const Item = {
    ConfigureMiddleware
};

export default Item;
