class PoFilterNames {
    static BERRY_TYPE = 'BerryType';
    static BERRY_FIELD_TYPE = 'BerryFieldType';
    static TYPE_OF_CHANGE = 'TypeOfChange';
    static CHANNEL = 'Channel';
    static DELIVERY_REQUESTED = 'DeliveryRequested';
    static MODE_OF_DELIVERY = 'ModeOfDelivery';
    static EMPLOYEE_RESPONSIBLE = 'EmployeeResponsible';
    static INVOICE_ACCOUNT_NAME = 'InvoiceAccountName';
    static INVOICE_ACCOUNT_NUMBER = 'InvoiceAccountNumber';
    static ITEM_NUMBER = 'ItemNbr';
    static PO_NUMBER = 'PoNumber';
    static RWH = 'RWH';
    static SALESREP = 'SalesRep';
    static SHIPMENTREQUESTED = 'ShipmentRequested';
    static SHIPTO = 'ShipTo';
    static STATUS = 'Status';
    static WAREHOUSE = 'Warehouse';
    static POOLWEEK = 'Poolweek';
    static PO_TYPE = 'POType';
}

export default PoFilterNames;
