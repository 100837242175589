import { withAuth } from '@okta/okta-react';
import { setPageTitleAction, setErrorsAction } from 'actions/actions';
import MasterDataUtilities from 'data/MasterDataUtilities';
import React from 'react';
import { connect } from 'react-redux';
import APIEndPoints from 'services/api';
import { DrcMain, DrcSelect, DrcPanel, DrcMediaQueries, DrcTooltip, DrcDialog, DrcButton, DrcPageMaintenance } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { DrcInput } from 'driscolls-react-components/dist/index.es';
import Customer from 'data/middleware/customer';

const styles = (theme) => ({
    customerMaintPanel: {
        marginBottom: '0 !important',
        '& br': {
            clear: 'both'
        },
        borderRadius: '10px',
        ['@media ' + DrcMediaQueries.tablet]: {
            maxWidth: '100% !important'
        }
    },
    actionGridStyles: {
        '& .p-datatable-scrollable-view': {
            width: '100% !important'
        },
        marginTop: '0 !important'
    },
    gridStyles: {
        marginTop: '0 !important',
        '& .p-datatable-thead tr:only-child th': {
            height: '75px'
        },
        '& .p-datatable-scrollable-header': {
            height: '70px'
        }
    }
});

const MAPPING_ITEMS = [
    //{ label: 'Allowances & Charges', value: 'Allowances' }, This is Phase 2 or later
    { label: 'Details', value: 'CustomerDetails', endpoint: APIEndPoints.CUSTOMERS },
    //{ label: 'Delivery Terms', value: 'CustomerDeliveryTerms', endpoint: APIEndPoints.CUSTOMER_DELIVERY_TERMS },
    { label: 'Items', value: 'CustomerItems', endpoint: APIEndPoints.CUSTOMER_ITEMS_ALL },
    //{ label: 'Modes of Delivery', value: 'CustomerModeOfDelivery', endpoint: APIEndPoints.CUSTOMER_MODE_OF_DELIVERY },
    { label: 'RWHs', value: 'CustomerWarehouse', endpoint: APIEndPoints.CUSTOMER_WAREHOUSES }
];

const defaultColumnProperties = {
    resizable: true,
    resizableColumns: true,
    filterPlaceholder: 'Search...',
    filter: true
};

const HIDE_ACTION_COLUMN = 0;
const SHOW_ACTION_COLUMN = 2;

class customerMaintenance extends React.Component {
    constructor(props) {
        super(props);

        this.grid = React.createRef();

        this.state = {
            successfulUpdate: false,
            ItemID: '',
            itemOption: '',
            itemOptions: [],
            addDialog: false,
            maintenanceData: [],
            selectedCustomer: '',
            selectedPage: '',
            selectedCustomerOption: null,
            selectedPageOption: null,
            maintenanceColumns: [],
            pageTitle: 'Customer Maintenance',
            subTitle: '',
            pageType: '',
            ItemDescription: '',
            showErrorMessage: false,
            showAddButton: false
        };

        this.CustMaintColumns = [
            {
                name: 'CustomerDetails',
                value: [
                    {
                        key: 'ExternalCustomerID',
                        name: (
                            <DrcTooltip tipText="External Customer ID">
                                <span>External Customer ID</span>
                            </DrcTooltip>
                        ),
                        filter: true,
                        filterPlaceholder: 'Search by name'
                    },
                    {
                        key: 'ExternalCustomerName',
                        name: (
                            <DrcTooltip tipText="External Customer Name">
                                <span>External Customer Name</span>
                            </DrcTooltip>
                        ),
                        filter: true
                    },
                    {
                        key: 'CustomerName',
                        name: (
                            <DrcTooltip tipText="Customer Name">
                                <span>Customer Name</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'BillToName',
                        name: (
                            <DrcTooltip tipText="Bill To Name">
                                <span>Bill To Name</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'BillToNumber',
                        name: (
                            <DrcTooltip tipText="Bill To Number">
                                <span>Bill To Number</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'ShipToName',
                        name: (
                            <DrcTooltip tipText="Ship To Name">
                                <span>Ship To Name</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'ShipToNumber',
                        name: (
                            <DrcTooltip tipText="Ship To Number">
                                <span>Ship To Number</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'DUNSNbr',
                        name: (
                            <DrcTooltip tipText="DUNS #">
                                <span>DUNS #</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'GLNNbr',
                        name: (
                            <DrcTooltip tipText="GLNN">
                                <span>GLNN</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'ISAID',
                        name: (
                            <DrcTooltip tipText="ISAID">
                                <span>ISAID</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'BuyerVendorNbr',
                        name: (
                            <DrcTooltip tipText="Buyer Vendor Number">
                                <span>Buyer Vendor Number</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'DriscollsID',
                        name: (
                            <DrcTooltip tipText="Driscolls ID">
                                <span>Driscolls ID</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'CompanyID',
                        name: (
                            <DrcTooltip tipText="Company ID">
                                <span>Company ID</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'Status',
                        name: (
                            <DrcTooltip tipText="Status">
                                <span>Status</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'PaymentTerm',
                        name: (
                            <DrcTooltip tipText="Payment Term">
                                <span>Payment Term</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'DeliveryTerm',
                        name: (
                            <DrcTooltip tipText="Delivery Term">
                                <span>Delivery Term</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'FSD',
                        name: (
                            <DrcTooltip tipText="FSD">
                                <span>FSD</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'DeliveryMode',
                        name: (
                            <DrcTooltip tipText="Delivery Mode">
                                <span>Delivery Mode</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    }
                ].map((c) => ({ ...c, ...defaultColumnProperties }))
            },
            {
                name: 'CustomerDeliveryTerms',
                value: [
                    {
                        key: 'DeliveryTermCode',
                        name: (
                            <DrcTooltip tipText="Delivery Term Code">
                                <span>Delivery Term Code</span>
                            </DrcTooltip>
                        ),
                        inputType: 'Select',
                        selectOptions: this.props.deliveryTermCodeOptions
                    },
                    {
                        key: 'CustDeliverTermCode',
                        name: (
                            <DrcTooltip tipText="Customer Delivery Term Code">
                                <span>Customer Delivery Term Code</span>
                            </DrcTooltip>
                        ),
                        inputType: 'Select',
                        selectOptions: this.props.deliveryTermCodeOptions
                    },
                    {
                        key: 'CustomerId',
                        name: (
                            <DrcTooltip tipText="Customer ID">
                                <span>Customer ID</span>
                            </DrcTooltip>
                        ),
                        inputType: 'Select',
                        selectOptions: this.props.customerIds
                    },
                    {
                        key: 'CustDeliverTermDesc',
                        name: (
                            <DrcTooltip tipText="Customer Delivery Term Description">
                                <span>Customer Delivery Term Description</span>
                            </DrcTooltip>
                        )
                    }
                ].map((c) => ({ ...c, ...defaultColumnProperties }))
            },
            {
                name: 'CustomerItems',
                value: [
                    {
                        key: 'ItemNbr',
                        name: (
                            <DrcTooltip tipText="Item Number">
                                <span>Item Number</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'ItemType',
                        name: (
                            <DrcTooltip tipText="Item Type">
                                <span>Item Type</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'Description',
                        name: (
                            <DrcTooltip tipText="Description">
                                <span>Description</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'BerryType',
                        name: (
                            <DrcTooltip tipText="Berry Type">
                                <span>Berry Type</span>
                            </DrcTooltip>
                        ),
                        value: 'test',
                        isDisabled: true
                    },
                    {
                        key: 'CustomerItemId',
                        inputType: 'regex',
                        regex: /^[A-Za-z0-9!@#$%^&*()_/]+$/,
                        name: (
                            <DrcTooltip tipText="Customer Item ID">
                                <span>Customer Item ID</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'ShipToNumber',
                        name: (
                            <DrcTooltip tipText="Ship to Number">
                                <span>Ship to Number</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'ShipToName',
                        name: (
                            <DrcTooltip tipText="Customer Name">
                                <span>Customer Name</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'CustomerItemDescription',
                        name: (
                            <DrcTooltip tipText="Customer Item Description">
                                <span>Customer Item Description</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'CompanyId',
                        name: (
                            <DrcTooltip tipText="Company ID">
                                <span>Company ID</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'UPCNbr',
                        name: (
                            <DrcTooltip tipText="UPC">
                                <span>UPC</span>
                            </DrcTooltip>
                        ),
                        validationType: 'number',
                        isDisabled: true
                    },
                    {
                        key: 'GTIN',
                        name: (
                            <DrcTooltip tipText="GTIN">
                                <span>GTIN</span>
                            </DrcTooltip>
                        ),
                        validationType: 'number',
                        isDisabled: true
                    },
                    {
                        key: 'Status',
                        name: (
                            <DrcTooltip tipText="Status">
                                <span>Status</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'DUNSNbr',
                        name: (
                            <DrcTooltip tipText="DUNS Number">
                                <span>DUNS Number</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    }
                ].map((c) => ({ ...c, ...defaultColumnProperties }))
            },
            {
                name: 'CustomerModeOfDelivery',
                value: [
                    {
                        key: 'Code',
                        name: (
                            <DrcTooltip tipText="Delivery ID">
                                <span>Delivery ID</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'CustomerDeliveryCode',
                        name: (
                            <DrcTooltip tipText="Customer Delivery Code">
                                <span>Customer Delivery Code</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    },
                    {
                        key: 'CustomerId',
                        name: (
                            <DrcTooltip tipText="Customer Delivery ID">
                                <span>Customer Delivery ID</span>
                            </DrcTooltip>
                        ),
                        inputType: 'Select',
                        selectOptions: this.props.customerIds
                    },
                    {
                        key: 'CompanyId',
                        name: (
                            <DrcTooltip tipText="Company ID">
                                <span>Company ID</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    }
                ].map((c) => ({ ...c, ...defaultColumnProperties }))
            },
            {
                name: 'CustomerWarehouse',
                value: [
                    {
                        key: 'ShipToNumber',
                        name: (
                            <DrcTooltip tipText="Customer Number">
                                <span>Customer Number</span>
                            </DrcTooltip>
                        ),
                        inputType: 'Select',
                        selectOptions: this.props.shipToNumbers
                    },
                    {
                        key: 'ReceivingWarehouse',
                        name: (
                            <DrcTooltip tipText="Receiving Warehouse">
                                <span>Receiving Warehouse</span>
                            </DrcTooltip>
                        ),
                        resizable: true
                    },
                    {
                        key: 'WarehouseDescription',
                        name: (
                            <DrcTooltip tipText="Warehouse Description">
                                <span>Warehouse Description</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'Address',
                        name: (
                            <DrcTooltip tipText="Address">
                                <span>Address</span>
                            </DrcTooltip>
                        )
                    },
                    {
                        key: 'DUNSNbr',
                        name: (
                            <DrcTooltip tipText="DUNS Nbr">
                                <span>DUNS Nbr</span>
                            </DrcTooltip>
                        ),
                        isDisabled: true
                    }
                ].map((c) => ({ ...c, ...defaultColumnProperties }))
            }
        ];

        let filteredColumns = this.CustMaintColumns.find((item) => {
            return item.name === this.state.pageType ? item.value : null;
        });

        this.columns = filteredColumns ? filteredColumns.value : [];

        this.CustMaintData = [
            { name: 'CustomerItems', value: APIEndPoints.CUSTOMER_ITEMS_ALL },
            { name: 'CustomerDeliveryTerms', value: APIEndPoints.CUSTOMER_DELIVERY_TERMS },
            { name: 'CustomerModeOfDelivery', value: APIEndPoints.CUSTOMER_MODE_OF_DELIVERY },
            { name: 'CustomerWarehouse', value: APIEndPoints.CUSTOMER_WAREHOUSES },
            { name: 'CustomerDetails', value: APIEndPoints.CUSTOMERS }
        ];

        let filteredAPIEndPoints = this.CustMaintData.find((item) => {
            return item.name === this.state.pageType ? item.value : null;
        });

        this.dropdownDataChanged = false;

        this.APIEndPoints = filteredAPIEndPoints ? filteredAPIEndPoints.value : '';

        this.getData = this.getData.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleSelectChangeOfCustomer = this.handleSelectChangeOfCustomer.bind(this);
        this.handleSelectChangeOfPage = this.handleSelectChangeOfPage.bind(this);
    }

    handleSelectChangeOfCustomer(option) {
        if (option === null && this.state.selectedCustomer !== '') {
            this.dropdownDataChanged = true;
            this.props.history.push('/Admin/Customer/All/CustomerDetails/');
            this.setState({ selectedPage: 'Details' });
        } else if (this.state.selectedCustomer !== option.value) {
            this.dropdownDataChanged = true;
            this.props.history.push('/Admin/Customer/' + option.value + '/' + this.state.selectedPage + '/');
            this.selectedCustomerCompanyId = this.props.customers.find((item) => item.CustomerID === option.value).CompanyID;
        }
    }

    handleSelectChangeOfPage(option) {
        this.props.auth.getAccessToken().then((token) => {
            if (this.state.selectedPage !== option.value) {
                this.dropdownDataChanged = true;
                this.props.history.push('/Admin/Customer/' + this.state.selectedCustomer + '/' + option.value + '/');
                this.setState({
                    showAddButton:
                        (option.value === 'CustomerItems' || this.state.selectedPage === 'CustomerItems') &&
                        DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || [])
                });

                if (this.grid && this.grid.current && this.grid.current.onLoad) {
                    this.grid.current.onLoad();
                }
            }
        });
    }

    componentDidMount() {
        this.onLoad();
    }

    async componentWillUnmount() {
        this.setState({ itemOptions: [] });
    }

    componentDidUpdate() {
        if (this.dropdownDataChanged) {
            this.dropdownDataChanged = false;
            this.onLoad();
        }
    }

    setItemOptions = async () => {
        var token = await this.props.auth.getAccessToken();
        var itemsResponse = await Middleware.Send('CustomerItems', token, APIEndPoints.GET_ITEMS_FOR_COMPANY(this.selectedCustomerCompanyId));
        var itemOptions = [];

        itemOptions = (itemsResponse.Results || []).map((item) => {
            return { label: item.ItemNbr + ' - ' + item.Description, value: item.ItemNbr + '::::' + item.Description };
        });

        var seenItems = [];
        var distinctItemOptions = [];

        itemOptions.forEach((itemOption) => {
            if (seenItems.includes(itemOption.value)) {
                return;
            }

            distinctItemOptions.push(itemOption);
            seenItems.push(itemOption.value);
        });

        distinctItemOptions = distinctItemOptions.sort((itemA, itemB) => {
            return itemA.value.localeCompare(itemB.value);
        });

        this.setState({ itemOptions: distinctItemOptions });
    };

    async onLoad() {
        var token = await this.props.auth.getAccessToken();

        this.setState({
            isAdmin: DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || [])
        });

        var { CustomerID, PageType } = this.props.match.params;

        if (CustomerID !== this.state.selectedCustomer || PageType !== this.state.selectedPage) {
            var customerOption = this.props.customerIdOptions.find((o) => o.value.toString() === CustomerID);
            if (customerOption !== undefined) {
                this.selectedCustomerCompanyId = this.props.customers.find((item) => item.CustomerID === customerOption.value).CompanyID;
            }

            var pageOption = MAPPING_ITEMS.find((i) => i.value === PageType);
            var columns = this.CustMaintColumns.find((c) => c.name === PageType);
            const subTitle = pageOption ? pageOption.label : '';

            this.setState(
                {
                    selectedCustomer: CustomerID,
                    selectedPage: PageType,
                    selectedCustomerOption: customerOption || null,
                    selectedPageOption: pageOption || null,
                    maintenanceColumns: columns ? columns.value : [],
                    maintenanceData: [],
                    pageTitle: 'Customer Maintenance: ' + (customerOption ? customerOption.label : ''),
                    subTitle,
                    pageType: this.props.match.params.PageType,
                    showAddButton: PageType === 'CustomerItems' && DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || [])
                },
                this.getData
            );

            this.props.setPageTitle(this.state.pageTitle + ' ' + subTitle);
        }
    }

    getMapping = () => {
        if (!this.state.selectedPageOption) {
            return '';
        }

        var option = MAPPING_ITEMS.find((m) => m.label === this.state.selectedPageOption.label);

        if (option) {
            return option.value;
        }

        return '';
    };

    getEndpoint = () => {
        if (!this.state.selectedPageOption) {
            return '';
        }

        var option = MAPPING_ITEMS.find((m) => m.label === this.state.selectedPageOption.label);

        if (option) {
            return (
                option.endpoint +
                (this.state.selectedCustomer && this.state.selectedCustomer !== 'All' ? '?customerid=' + this.state.selectedCustomer : '')
            );
        }

        return '';
    };

    getData = () => {
        var map = this.getMapping();
        var endpoint = this.getEndpoint();

        if (map.length <= 0 || endpoint.length <= 0) {
            return;
        }

        if (this.grid && this.grid.current && this.grid.current.onLoad) {
            this.grid.current.onLoad();
        }

        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send(map, token, endpoint).then((data) => {
                var results = data.Results || [];
                if (results.length > 0) {
                    var i = 0;
                    results = data.Results.map((elm) => {
                        elm.Status = elm.Status === '0' ? 'Active' : 'Inactive';
                        elm.key = i++;
                        return elm;
                    });
                }
                this.setState({ maintenanceData: results });
            });
        });
    };

    onAdd = async () => {
        var ItemNbr = this.state.ItemNo.split(':')[0];
        var addObj = {
            ItemNbr,
            CustomerItemId: this.state.ItemID,
            CustomerItemDescription: this.state.ItemDescription,
            DUNSNbr: this.state.selectedCustomerOption.duns
        };
        var map = this.getMapping();
        var endpoint = this.getEndpoint();

        if (map.length <= 0 || endpoint.length <= 0) {
            return;
        }
        addObj.CreatedDateTime = new Date().toISOString();
        addObj.LastUpdatedDateTime = new Date().toISOString();
        addObj.Enabled = true;
        addObj.pageType = this.state.pageType;
        var token = await this.props.auth.getAccessToken();
        addObj.LastUpdatedBy = DuAuthenticationUtilities.GetEmail(token);
        addObj.CreatedBy = DuAuthenticationUtilities.GetEmail(token);
        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send(map, token, endpoint, 'POST', addObj)
                .then(() => {
                    this.setState({ addDialog: false });
                    this.setState({
                        ItemNo: '',
                        ItemDescription: '',
                        ItemID: ''
                    });

                    this.getData();
                    this.setState({ successfulUpdate: true });
                })
                .catch((error) => {
                    if (error.data && error.data.response && error.data.response.data && error.data.response.data.Message) {
                        this.props.setErrorsAction('Failed to add', error.data.response.data.Message);
                    } else this.props.setErrorsAction('Failed to add');
                });
        });
    };

    onEdit = (oldValue, newValue) => {
        var map = this.getMapping();
        var endpoint = this.getEndpoint();

        if (map.length <= 0 || endpoint.length <= 0) {
            return;
        }
        newValue.Status = newValue.Status === 'Active' ? '0' : '1';
        newValue.LastUpdatedDateTime = new Date().toISOString();
        this.props.auth.getAccessToken().then((token) => {
            newValue.LastUpdatedBy = DuAuthenticationUtilities.GetEmail(token);
            Middleware.Send(map, token, endpoint, 'POST', newValue, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.response) {
                        this.setState({ showErrorMessage: true });
                    } else {
                        this.getData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    onDelete = (deleteVal) => {
        var map = this.getMapping();
        var endpoint = this.getEndpoint();

        if (map.length <= 0 || endpoint.length <= 0) {
            return;
        }

        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send(map, token, endpoint, 'DELETE', deleteVal).then(this.getData());
        });
    };

    closeErrorMessage = () => {
        this.setState({ showErrorMessage: false });
    };

    showAddDialog = () => {
        this.setItemOptions();

        this.setState({ addDialog: true });
    };

    handleOnChangeOfDialogFields = async (e, field) => {
        if (field === 'ItemNo') {
            console.log(e.value);
            this.setState({ ItemNo: e.value });
        } else await this.setState({ [field]: e.target.value });
        console.log(this.state);
    };

    checkSaveStatus = () => {
        return (
            this.state.ItemNo &&
            this.state.ItemNo !== '' &&
            this.state.ItemDescription &&
            this.state.ItemDescription !== '' &&
            this.state.ItemID &&
            this.state.ItemID !== ''
        );
    };

    render() {
        const { classes, isMasterDataInitialized, customerIdOptions } = this.props;
        let showaActionColumn =
            this.state.selectedCustomer === '' || this.state.selectedCustomer === 'All' || !this.state.isAdmin
                ? HIDE_ACTION_COLUMN
                : SHOW_ACTION_COLUMN;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcMain maxWidth="100%" transparent>
                <DrcPanel className={classes.customerMaintPanel} maxWidth="500px">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <DrcSelect
                                label="Customer"
                                placeholder="Select a customer"
                                options={customerIdOptions}
                                value={this.state.selectedCustomerOption}
                                onChange={this.handleSelectChangeOfCustomer}
                                isClearable={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DrcSelect
                                label="Type"
                                placeholder="Select item"
                                options={MAPPING_ITEMS}
                                value={this.state.selectedPageOption}
                                isDisabled={this.state.selectedCustomer === '' || this.state.selectedCustomer === 'All'}
                                onChange={this.handleSelectChangeOfPage}
                            />
                        </Grid>
                    </Grid>
                </DrcPanel>
                <DrcPageMaintenance
                    ref={this.grid}
                    textOptions={{ PageTitle: '', AddBtn: 'Add', ExportBtn: 'Download', CancelBtn: 'Cancel', SaveBtn: 'Save' }}
                    className={showaActionColumn === 0 ? `${classes.actionGridStyles}` : `${classes.gridStyles}`}
                    fullHeightOffset={10}
                    fullWidth={true}
                    data={this.state.maintenanceData}
                    type={`${this.state.subTitle} Maintenance`}
                    columns={this.state.maintenanceColumns.length === 0 ? [{ key: 'ExternalCustomerID' }] : this.state.maintenanceColumns}
                    settings={{
                        EnableExport: true,
                        ShowCount: true,
                        EnableAdd: this.state.showAddButton,
                        EnableEdit: true,
                        EnableDelete: false,
                        ActionColumnSetting: showaActionColumn,
                        OverrideAdd: this.showAddDialog
                    }}
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    height={window.innerHeight - 400}
                />
                {this.state.showErrorMessage ? (
                    <DrcDialog
                        open={this.state.showErrorMessage}
                        title={'Oops! Something went wrong.'}
                        buttons={
                            <DrcButton isPrimary onClick={this.closeErrorMessage} floatRight>
                                Try, Try Again
                            </DrcButton>
                        }
                    >
                        I would suggest to check the External Customer ID. It should be unique across all customers.
                    </DrcDialog>
                ) : null}
                <DrcDialog
                    fullActionWrapper
                    open={this.state.addDialog}
                    title={'Add New Customer Item'}
                    buttons={
                        <div>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ addDialog: false, itemOption: '', ItemDescription: '', ItemID: '' });
                                }}
                                floatRight
                            >
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary disabled={!this.checkSaveStatus()} onClick={this.onAdd} floatRight>
                                Add
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <DrcSelect
                                required
                                label={'Item Number'}
                                InputLabelProps={{ shrink: true }}
                                value={this.state.itemOptions.filter((e) => e.value === this.state.ItemNo)[0]}
                                onChange={(e) => this.handleOnChangeOfDialogFields(e, 'ItemNo')}
                                options={this.state.itemOptions}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <DrcInput
                                floatRight
                                label={'Customer Item Description'}
                                required
                                value={this.state.ItemDescription}
                                onChange={(e) => this.handleOnChangeOfDialogFields(e, 'ItemDescription')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <DrcInput
                                floatRight
                                label={'Customer Item ID'}
                                required
                                value={this.state.ItemID}
                                onChange={(e) => this.handleOnChangeOfDialogFields(e, 'ItemID')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>
                </DrcDialog>
                <DrcDialog
                    title={'Added new item successfully'}
                    open={this.state.successfulUpdate}
                    buttons={
                        <div>
                            <DrcButton isPrimary onClick={() => this.setState({ successfulUpdate: false })}>
                                OK, I'm Done
                            </DrcButton>
                        </div>
                    }
                ></DrcDialog>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        customers: state.masterReducer.customers,

        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        // main customer id dropdown options
        customerIdOptions: state.masterReducer.customerIdOptions,
        // customer id dropdown options
        customerIds: state.masterReducer.customers.map((customer) => {
            return { label: customer.CustomerID ? customer.CustomerID.toFixed() : '', value: customer.CustomerID, duns: Customer.DUNSNbr };
        }),
        companyIds: state.masterReducer.customers.map((customer) => {
            return { label: customer.CompanyID, value: customer.CompanyID };
        }),
        shipToNumbers: state.masterReducer.customers.map((customer) => {
            return { label: customer.ShipToNumber, value: customer.ShipToNumber };
        }),
        customerModeOfDeliveryIDs: state.masterReducer.deliveryModes.map((modeOfDelivery) => {
            return { label: modeOfDelivery.Id, value: modeOfDelivery.Id };
        }),
        customerDeliveryCodes: state.masterReducer.deliveryModes.map((modeOfDelivery) => {
            return { label: modeOfDelivery.CustomerDeliveryCodes, value: modeOfDelivery.CustomerDeliveryCodes };
        }),
        deliveryTermCodeOptions: state.masterReducer.customerDeliveryTerms.map((deliveryTermOptions) => {
            return { labels: deliveryTermOptions.value, value: deliveryTermOptions.value };
        })
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(customerMaintenance)));
