import { Middleware } from 'one-ring';

const MasterDataApiMappings = [
    Middleware.CreateMapping('Type', 'LookupType'),
    Middleware.CreateMapping('Value', 'LookupCode'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateSortMapping('Label', 'Meaning'),
    Middleware.CreateMapping('Order', 'DisplaySequence'),
    Middleware.CreateArrayMapping('Results')
];

const WarehouseMappings = [
    Middleware.CreateIndexMapping('WarehouseId', 'WarehouseId'),
    Middleware.CreateSortMapping('WarehouseNbr', 'WarehouseNbr'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('StreetAddr1', 'StreetAddr1'),
    Middleware.CreateMapping('StreetAddr2', 'StreetAddr2'),
    Middleware.CreateMapping('City', 'City'),
    Middleware.CreateMapping('State', 'State'),
    Middleware.CreateMapping('Country', 'Country'),
    Middleware.CreateMapping('Zip', 'Zip'),
    Middleware.CreateMapping('AddAddrComponents', 'AddAddrComponents'),
    Middleware.CreateMapping('Purpose', 'Purpose'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('WarehouseType', 'WarehouseType'),
    Middleware.CreateMapping('OwnershipType', 'OwnershipType'),
    Middleware.CreateMapping('OwnerOrganization', 'OwnerOrganization'),
    Middleware.CreateMapping('CHEPNbr', 'CHEPNbr'),
    Middleware.CreateMapping('OverallStatus', 'OverallStatus'),
    Middleware.CreateMapping('ReceivingAreaId', 'ReceivingAreaId'),
    Middleware.CreateArrayMapping('Results')
];

const SalesRepMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('SalesRepId', 'SalesRepID'),
    Middleware.CreateSortMapping('Name', 'EmployeeName'),
    Middleware.CreateMapping('EmployeeId', 'EmployeeID'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateArrayMapping('Results')
];

const DeliveryModeMappings = [
    Middleware.CreateIndexMapping('Id', 'Id'),
    Middleware.CreateMapping('CustomerDeliveryId', 'CustomerDeliveryId'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateSortMapping('Value', 'Code'),
    Middleware.CreateMapping('Label', 'Description'),
    Middleware.CreateArrayMapping('Results')
];

const RWHMappings = [
    Middleware.CreateIndexMapping('Id', 'Id'),
    Middleware.CreateMapping('ShipToName', 'ShipToName'),
    Middleware.CreateMapping('ShipToNumber', 'ShipToNumber'),
    Middleware.CreateSortMapping('Description', 'WarehouseDescription'),
    Middleware.CreateMapping('ReceivingWarehouse', 'ReceivingWarehouse'),
    Middleware.CreateMapping('ExternalCustomerId', 'CustomerId'),
    Middleware.CreateMapping('CustomerName', 'CustomerName'),
    Middleware.CreateMapping('City', 'City'),
    Middleware.CreateMapping('State', 'State'),
    Middleware.CreateMapping('Country', 'Country'),
    Middleware.CreateMapping('Zip', 'ZipCode'),
    Middleware.CreateMapping('Address', 'Address'),
    Middleware.CreateArrayMapping('Results')
];

const ItemMappings = [
    Middleware.CreateMapping('Type', 'Type'),
    Middleware.CreateMapping('BerryTypeId', 'BerryTypeId'),
    Middleware.CreateMapping('CompanyID', 'CompanyID'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('GTIN', 'GTIN'),
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('UPCNbr', 'UPCNbr'),
    Middleware.CreateMapping('DUNsNbr', 'DUNsNbr'),
    Middleware.CreateMapping('Enabled', 'Enabled'),
    Middleware.CreateArrayMapping('Results')
];

const DemandPlanningDistrictMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('RecId', 'RecId'),
    Middleware.CreateMapping('RecVersion', 'RecVersion'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('DC', 'DC'),
    Middleware.CreateMapping('DCName', 'DCName'),
    Middleware.CreateMapping('PD', 'PD'),
    Middleware.CreateMapping('PDName', 'PDName'),
    Middleware.CreateMapping('Type', 'Type'),
    Middleware.CreateArrayMapping('Results')
];

const unitsOfMeasureMappings = [
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('ToUOMId', 'ToUOMId'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('FromUOMId', 'FromUOMId'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('Factor', 'Factor'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('isDeleted', 'isDeleted'),
    Middleware.CreateArrayMapping('Results')
];
const OBOMappings = [
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('LookupType', 'LookupType'),
    Middleware.CreateMapping('Meaning', 'Meaning'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateArrayMapping('Results')
];

const BerryTypeMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('Type', 'Type'),
    Middleware.CreateMapping('Value', 'Value'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('Label', 'Meaning'),
    Middleware.CreateMapping('Order', 'Order'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Enabled', 'Enabled'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('CustomerTerms', 'CustomerTerms'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('lastUpdatedNameAndTime', 'lastUpdatedNameAndTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateArrayMapping('Results')
];

const BerrySalesPlanGroupsMappings = [
    Middleware.CreateMapping('PlanGroupName', 'PlanGroupName'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('RecId', 'RecId'),
    Middleware.CreateArrayMapping('Results')
];

const PAYMENTMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('PaymentMethodCode', 'PaymentMethodCode'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('Period', 'Period'),
    Middleware.CreateMapping('PaymentType', 'PaymentType'),
    Middleware.CreateMapping('CustomerId', 'CustomerId'),
    Middleware.CreateMapping('CustomerPaymentMethod', 'CustomerPaymentMethod'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateArrayMapping('Results')
];

const carriercodeMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('CarrierCode', 'CarrierCode'),
    Middleware.CreateMapping('CarrierName', 'CarrierName'),
    Middleware.CreateMapping('ServiceLevel', 'ServiceLevel'),
    Middleware.CreateMapping('Address1', 'Address1'),
    Middleware.CreateMapping('Address2', 'Address2'),
    Middleware.CreateMapping('City', 'City'),
    Middleware.CreateArrayMapping('State', 'State'),
    Middleware.CreateMapping('Country', 'Country'),
    Middleware.CreateMapping('ZipCode', 'ZipCode'),
    Middleware.CreateMapping('Phone', 'Phone'),
    Middleware.CreateArrayMapping('Currency', 'Currency'),
    Middleware.CreateMapping('MaxWeight', 'MaxWeight'),
    Middleware.CreateMapping('MaxVolume', 'MaxVolume'),
    Middleware.CreateArrayMapping('VendorAcctNbr', 'VendorAcctNbr'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateArrayMapping('Results')
];

const deliveryTermsMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('LookupType', 'LookupType'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Meaning', 'Meaning'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateMapping('DisplaySequence', 'DisplaySequence'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('isDeleted', 'isDeleted'),
    Middleware.CreateMapping('Attribute2', 'Attribute2'),
    Middleware.CreateArrayMapping('Results')
];

const fsdMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('LookupType', 'LookupType'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Meaning', 'Meaning'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateMapping('DisplaySequence', 'DisplaySequence'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('isDeleted', 'isDeleted'),
    Middleware.CreateMapping('Attribute2', 'Attribute2'),
    Middleware.CreateArrayMapping('Results')
];

const ModesOfDeliveryMappings = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateArrayMapping('Results')
];

const PoolWeekMappings = [
    Middleware.CreateMapping('PoolWeek', 'PoolWeek'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateArrayMapping('Results', 'PoolWeeks')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('MasterDataApi', 'GET')) {
        Middleware.RegisterMapping('MasterDataApi', 'GET', MasterDataApiMappings, true);
        Middleware.RegisterMapping('MasterDataApi', 'POST', MasterDataApiMappings);
    }

    if (!Middleware.CheckMappingExists('Warehouse', 'GET')) {
        Middleware.RegisterMapping('Warehouse', 'GET', WarehouseMappings, true);
        Middleware.RegisterMapping('Warehouse', 'POST', WarehouseMappings);
    }

    if (!Middleware.CheckMappingExists('DeliveryMode', 'GET')) {
        Middleware.RegisterMapping('DeliveryMode', 'GET', DeliveryModeMappings, true);
        Middleware.RegisterMapping('DeliveryMode', 'POST', DeliveryModeMappings);
        Middleware.RegisterMapping('DeliveryMode', 'DELETE', DeliveryModeMappings);
    }

    if (!Middleware.CheckMappingExists('ModesOfDelivery', 'GET')) {
        Middleware.RegisterMapping('ModesOfDelivery', 'GET', ModesOfDeliveryMappings, true);
    }

    if (!Middleware.CheckMappingExists('SalesRep', 'GET')) {
        Middleware.RegisterMapping('SalesRep', 'GET', SalesRepMappings, true);
        Middleware.RegisterMapping('SalesRep', 'POST', SalesRepMappings);
    }

    if (!Middleware.CheckMappingExists('RWH', 'GET')) {
        Middleware.RegisterMapping('RWH', 'GET', RWHMappings, true);
        Middleware.RegisterMapping('RWH', 'POST', RWHMappings);
    }

    if (!Middleware.CheckMappingExists('Item', 'GET')) {
        Middleware.RegisterMapping('Item', 'GET', ItemMappings);
        Middleware.RegisterMapping('Item', 'POST', ItemMappings);
    }

    if (!Middleware.CheckMappingExists('DemandPlanningDistrict', 'GET')) {
        Middleware.RegisterMapping('DemandPlanningDistrict', 'GET', DemandPlanningDistrictMappings);
    }

    if (!Middleware.CheckMappingExists('unitsOfMeasure', 'GET')) {
        Middleware.RegisterMapping('unitsOfMeasure', 'GET', unitsOfMeasureMappings);
    }

    if (!Middleware.CheckMappingExists('OBO', 'GET')) {
        Middleware.RegisterMapping('OBO', 'GET', OBOMappings);
        Middleware.RegisterMapping('OBO', 'POST', OBOMappings);
    }

    if (!Middleware.CheckMappingExists('BerryType', 'GET')) {
        Middleware.RegisterMapping('BerryType', 'GET', BerryTypeMappings);
        Middleware.RegisterMapping('BerryType', 'POST', BerryTypeMappings);
    }

    if (!Middleware.CheckMappingExists('BerrySalesPlanGroups', 'GET')) {
        Middleware.RegisterMapping('BerrySalesPlanGroups', 'GET', BerrySalesPlanGroupsMappings);
    }

    if (!Middleware.CheckMappingExists('PaymentList', 'GET')) {
        Middleware.RegisterMapping('PaymentList', 'GET', PAYMENTMappings);
        Middleware.RegisterMapping('PaymentList', 'POST', PAYMENTMappings);
    }

    if (!Middleware.CheckMappingExists('carriercodeList', 'GET')) {
        Middleware.RegisterMapping('carriercodeList', 'GET', carriercodeMappings);
        Middleware.RegisterMapping('carriercodeList', 'POST', carriercodeMappings);
    }

    if (!Middleware.CheckMappingExists('FSD', 'GET')) {
        Middleware.RegisterMapping('FSD', 'GET', fsdMappings);
    }

    if (!Middleware.CheckMappingExists('DeliveryTerms', 'GET')) {
        Middleware.RegisterMapping('DeliveryTerms', 'GET', deliveryTermsMappings);
    }

    if (!Middleware.CheckMappingExists('PoolWeek', 'GET')) {
        Middleware.RegisterMapping('PoolWeek', 'GET', PoolWeekMappings);
    }
}

const MasterData = {
    ConfigureMiddleware
};

export default MasterData;
