import { SET_SO_CONVERSION_DETAILS, CLEAR_SO_CONVERSION_DETAILS } from 'actions/SOConversionActions';

const initialState = {
    soConversionData: []
};

const soConversionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SO_CONVERSION_DETAILS:
            if (!action.payload.soConversionData) {
                return state;
            }

            var data = action.payload.soConversionData;

            data.Items = data.Items.sort((a, b) => {
                var aItem = Number(a.POLineHeader.DriscollsItemNbr);
                var bItem = Number(b.POLineHeader.DriscollsItemNbr);

                if (aItem !== bItem) {
                    return aItem - bItem;
                }

                return a.POLineHeader.CustomerRequestedPrice - b.POLineHeader.CustomerRequestedPrice;
            });

            data.ServiceLines = data.ServiceLines.sort((a, b) => {
                var aItem = Number(a.ItemNbr);
                var bItem = Number(b.ItemNbr);

                if (aItem !== bItem) {
                    return Number(a.ItemNbr) - Number(b.ItemNbr);
                }

                return a.SalesPrice - b.SalesPrice;
            });

            return Object.assign({}, state, {
                soConversionData: data
            });
        case CLEAR_SO_CONVERSION_DETAILS:
            return { ...state, soConversionData: initialState.soConversionData };
        default:
            return state;
    }
};

export default soConversionReducer;
