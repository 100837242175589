import {
    SET_QUOTE_LIST,
    CLEAR_QUOTE_LIST,
    ADD_SELECTED_QUOTE,
    REMOVE_SELECTED_QUOTE,
    ADD_PO_LIST,
    SET_PO_REVIEW_LIST,
    SET_SELECTED_PO,
    SET_SELECTED_PO_REF_IN_SO,
    SET_SELECTED_REVIEW_PO,
    SET_QUOTE_DETAILS_ID,
    SET_QUOTE_DETAILS,
    CLEAR_SELECTED_PO,
    SET_SHOW_PO_LINE_ITEMS,
    ADD_PO_LINE_ITEMS,
    OPEN_CLOSE_CANCEL_DIALOG,
    OPEN_CLOSE_CHANGE_STATUS_DIALOG,
    CLEAR_SELECTED_REVIEW_PO,
    SET_SELECTED_PO_REF_IN_EDIT,
    SET_CANCEL_REVIEW_PO_PAYLOAD
} from 'actions/PoListActions';
import { DuDateUtilities } from 'driscolls-react-utilities';
import SearchUtilities from 'data/SearchUtilities';

const initialState = {
    quoteList: [],
    quoteDetails: [],
    selectedQuote: {},
    multiPoMatchQuote: [],
    poList: { Results: [] },
    poReviewList: { Results: [] },
    selectedPo: {},
    selectedPoCount: 0,
    selectedPoRef: null,
    selectedPoRefs: [],
    title: {},
    selectedQuoteId: null,
    showQuoteDetails: false,
    showPoLineItems: false,
    poLineItems: {},
    showCancelDialog: false,
    showChangeStatusDialog: false
};

const quoteListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUOTE_LIST:
            if (!action.payload.quoteList) {
                return state;
            }

            return Object.assign({}, state, {
                quoteList: action.payload.quoteList,
                selectedPoRef: action.payload.ref
            });
        case CLEAR_QUOTE_LIST:
            return Object.assign({}, state, {
                quoteList: initialState.quoteList,
                selectedQuote: state.selectedQuote
            });
        case ADD_SELECTED_QUOTE:
            return Object.assign({}, state, {
                selectedQuote: action.payload.selectedQuote
            });
        case REMOVE_SELECTED_QUOTE:
            return Object.assign({}, state, {
                selectedQuote: initialState.selectedQuote
            });
        case ADD_PO_LIST:
            var pos = action.payload.data.Results;
            var shouldClear = action.payload.clear || false;

            //NOTE: Add custom parameters for po's here to default them to a known good state.
            if (pos !== undefined) {
                pos.forEach((po) => {
                    var berryTypes = po.BerryTypes || [];

                    po.ShowLineItems = false;
                    po.BerryTypeString =
                        typeof berryTypes === 'string' ? berryTypes : berryTypes.length > 0 ? berryTypes.map((berry) => berry.label).join(' ') : '';

                    po.ShipmentRequestedString = DuDateUtilities.FormatDateFromIso(po.ShipmentRequested);
                    po.DeliveryRequestedString = DuDateUtilities.FormatDateFromIso(po.DeliveryRequested);
                    po.CreatedDateTimeString = DuDateUtilities.FormatDateTimeFromIso(po.CreatedDateTime);
                    po.PoDateTimeString = DuDateUtilities.FormatDateTimeFromIso(po.PoDateTime);
                });
            } else {
                pos = [];
            }

            var poList = action.payload.data;

            if (!shouldClear) {
                pos = pos.filter((poFilter) => !state.poList.Results.some((statePo) => statePo.PoHeaderId === poFilter.PoHeaderId));
            }

            var fullPoList = shouldClear ? pos : [...state.poList.Results, ...pos];

            poList.Results = fullPoList;
            return Object.assign({}, state, {
                poList,
                quoteList: shouldClear ? [] : state.quoteList,
                selectedPo: shouldClear ? {} : state.selectedPo,
                selectedPoCount: shouldClear ? 0 : state.selectedPoCount,
                selectedPoRef: shouldClear ? null : state.selectedPoRef,
                selectedPoRefs: shouldClear ? [] : state.selectedPoRefs,
                showPoLineItems: shouldClear ? false : state.showPoLineItems
            });
        case SET_PO_REVIEW_LIST:
            var posToReview = action.payload.data.Results;

            //NOTE: Add custom parameters for po's here to default them to a known good state.
            if (posToReview !== undefined) {
                posToReview.forEach((po) => {
                    var berryTypes = po.BerryTypes || [];

                    po.BerryTypeString =
                        typeof berryTypes === 'string' ? berryTypes : berryTypes.length > 0 ? berryTypes.map((berry) => berry.label).join(' ') : '';
                    po.PoDateTimeString = DuDateUtilities.FormatDateTimeFromIso(po.PoDateTime);
                    po.ShipmentRequestedString = DuDateUtilities.FormatDateFromIso(po.ShipmentRequested);
                    po.DeliveryRequestedString = DuDateUtilities.FormatDateFromIso(po.DeliveryRequested);
                    po.CreatedDateTimeString = DuDateUtilities.FormatDateTimeFromIso(po.CreatedDateTime);
                });
            } else {
                posToReview = [];
            }

            var poReviewList = action.payload.data;
            poReviewList.Results = posToReview;

            return Object.assign({}, state, {
                poReviewList
            });
        case SET_SELECTED_PO:
            if (!action.payload.poRef) {
                return state;
            }
            var changedPoIndex = SearchUtilities.FindPoIndexByRef(state.poList.Results, action.payload.poRef);
            var changedPo = state.poList.Results[changedPoIndex];
            var poRefIndex = SearchUtilities.FindPoIndexByRef(state.selectedPoRefs, action.payload.poRef);
            var isSelected = poRefIndex >= 0;

            if (isSelected !== action.payload.isSelected) {
                var poList_Set = state.poList;
                var selectedPoRefs_Set = state.selectedPoRefs;

                poList_Set.Results.splice(changedPoIndex, 1, changedPo);

                if (isSelected) {
                    selectedPoRefs_Set.splice(poRefIndex, 1);
                } else {
                    selectedPoRefs_Set.push(action.payload.poRef);
                }

                var selectedPo = {};

                if (!action.payload.isSelected && selectedPoRefs_Set.length === 1) {
                    selectedPo = poList_Set.Results.find((po) => {
                        return SearchUtilities.PoRefEqual(po, selectedPoRefs_Set[0]);
                    });
                }

                return Object.assign({}, state, {
                    selectedPoRef: action.payload.isSelected ? action.payload.poRef : selectedPoRefs_Set.length === 1 ? selectedPo : null,
                    selectedPoRefs: selectedPoRefs_Set,
                    poList: poList_Set,
                    selectedPo: action.payload.isSelected ? changedPo : selectedPoRefs_Set.length === 1 ? selectedPo : {},
                    selectedPoCount: selectedPoRefs_Set.length,
                    selectedReviewPo: {}
                });
            }
            //for SO conversion scenario select po updation
            //it is not updating since changed po is same as selectedPoRefs so it doesn't satisfy if condition and not updating selected po
            //added another variable check to update the selected po
            else if (action.payload.isSelected && action.payload.forceUpdateSelectedPo) {
                return Object.assign({}, state, {
                    selectedPo: action.payload.poRef
                });
            }
            return state;
        case SET_SELECTED_REVIEW_PO:
            let tempPolist = state.poReviewList.Results.length === 0 ? state.poList.Results : state.poReviewList.Results;
            var changedPoIndexForReview = SearchUtilities.FindPoIndexByRef(tempPolist, action.payload.poRef);
            var changedPoForReview = tempPolist[changedPoIndexForReview];

            return Object.assign({}, state, {
                selectedReviewPo: changedPoForReview || {}
            });
        case SET_SELECTED_PO_REF_IN_SO:
            return Object.assign({}, state, {
                selectedPoRefInSo: action.payload.poRef
            });
        case CLEAR_SELECTED_REVIEW_PO:
            return Object.assign({}, state, {
                selectedReviewPo: null
            });
        case SET_SHOW_PO_LINE_ITEMS:
            return Object.assign({}, state, {
                selectedPoRef: action.payload.poRef,
                selectedPo: state.poList.Results[SearchUtilities.FindPoIndexByRef(state.poList.Results, action.payload.poRef)],
                showPoLineItems: action.payload.ShowLineItems,
                poLineItems: []
            });
        case SET_QUOTE_DETAILS_ID:
            var selectedPoIndexForQuote = SearchUtilities.FindPoIndexByRef(state.poList.Results, action.payload.poRef);
            var selectedPoForQuote = state.poList.Results[selectedPoIndexForQuote];

            return Object.assign({}, state, {
                selectedQuoteId: action.payload.quoteId || null,
                showQuoteDetails: !!action.payload.quoteId && action.payload.quoteId.length >= 0,
                selectedPoRef: SearchUtilities.CreatePoRef(selectedPoForQuote),
                quoteDetails: []
            });
        case CLEAR_SELECTED_PO:
            return Object.assign({}, state, {
                quoteList: [],
                selectedPoCount: 0,
                selectedPoRef: null,
                selectedPoRefs: [],
                selectedPo: {}
            });
        case ADD_PO_LINE_ITEMS:
            return Object.assign({}, state, {
                poLineItems: action.payload.items
            });
        case SET_QUOTE_DETAILS:
            return Object.assign({}, state, {
                quoteDetails: action.payload.options
            });
        case OPEN_CLOSE_CANCEL_DIALOG:
            var selectedPoIndexForCancel = SearchUtilities.FindPoIndexByRef(state.poList.Results, action.payload.poRef);
            var selectedPoForCancel = state.poList.Results[selectedPoIndexForCancel];
            //If selectedPoForCancel is null dont set it to the state because it will clear the current selectedpo
            if (selectedPoForCancel) {
                return Object.assign({}, state, {
                    showCancelDialog: action.payload.poRef !== null,
                    selectedPoRef: action.payload.poRef,
                    selectedPo: selectedPoForCancel
                });
            } else {
                return Object.assign({}, state, {
                    showCancelDialog: action.payload.poRef !== null,
                    selectedPoRef: action.payload.poRef
                });
            }

        case OPEN_CLOSE_CHANGE_STATUS_DIALOG:
            var title = action.payload.title;

            return Object.assign({}, state, {
                showChangeStatusDialog: !!title && title.length > 0,
                title: title,
                status: action.payload.status,
                isReview: action.payload.isReview
            });
        case SET_SELECTED_PO_REF_IN_EDIT:
            return Object.assign({}, state, {
                selectedPoRefInSo: action.payload.poRef
            });
        case SET_CANCEL_REVIEW_PO_PAYLOAD:
            return Object.assign({}, state, {
                cancelPOReviewPayload: action.payload.payloadData
            });
        default:
            return state;
    }
};

export default quoteListReducer;
