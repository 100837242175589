import React from 'react';
import { Redirect } from 'react-router-dom';
import { Middleware } from 'one-ring';
import LoggingUtilities from 'data/LoggingUtilities';
import APIEndPoints from 'services/api';

const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;
const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours

var lastMasterDataLoadTime = null;
var hasLoggedMasterDataWarning = false;
var hasLoggedMasterDataInfo = false;
var redirectLocationReducerFunction = () => {};
//var setMasterDataInitialized = () => {};

const sortOrder = (data) => {
    return data.sort((a, b) => {
        return a.Order - b.Order;
    });
};

const masterDataCalls = [
    {
        name: 'MasterDataAPI',
        endpoint: APIEndPoints.STATUS,
        weight: 5,
        returnFunction: (data, props) => {
            props.setStatuses(sortOrder(data.Results));
        }
    },
    {
        name: 'DeliveryMode',
        endpoint: APIEndPoints.MODES_OF_DELIVERY,
        weight: 273,
        returnFunction: (data, props) => {
            props.setDeliveryModes(data.Results);
        }
    },
    {
        name: 'MasterDataAPI',
        endpoint: APIEndPoints.BERRY_TYPE,
        weight: 3,
        returnFunction: (data, props) => {
            props.setBerryTypes(sortOrder(data.Results));
        }
    },
    {
        name: 'Warehouse',
        endpoint: APIEndPoints.WAREHOUSE,
        weight: 200,
        returnFunction: (data, props) => {
            props.setWarehouses(data.Results);
        }
    },
    {
        name: 'Customer',
        endpoint: APIEndPoints.CUSTOMERS,
        weight: 17,
        returnFunction: (data, props) => {
            var customers = data.Results.sort((a, b) => {
                return (a.ShipToName || '').localeCompare(b.ShipToName || '');
            });

            props.setCustomers(customers);
        }
    },
    {
        name: 'RWH',
        endpoint: APIEndPoints.CUSTOMER_WAREHOUSES,
        weight: 12,
        returnFunction: (data, props) => {
            props.setReceivingWarehouses(data.Results);
        }
    },
    {
        name: 'MasterDataAPI',
        endpoint: APIEndPoints.CHANGE_TYPES,
        weight: 1,
        returnFunction: (data, props) => {
            props.setChangeTypes(data.Results);
        }
    },
    {
        name: 'MasterDataAPI',
        endpoint: APIEndPoints.CHANNEL,
        weight: 2,
        returnFunction: (data, props) => {
            props.setChannels(data.Results);
        }
    },
    {
        name: 'SalesRep',
        endpoint: APIEndPoints.SALES_REP,
        weight: 19,
        returnFunction: (data, props) => {
            props.setSalesReps(data.Results);
        }
    },
    {
        name: 'PaymentTerms',
        endpoint: APIEndPoints.PAYMENT_TERMS,
        weight: 456,
        returnFunction: (data, props) => {
            props.setPaymentTerms(data.Results);
        }
    },
    {
        name: 'FSD',
        endpoint: APIEndPoints.FSD,
        weight: 68,
        returnFunction: (data, props) => {
            props.setFSD(data.Results);
        }
    },
    {
        name: 'DeliveryTerms',
        endpoint: APIEndPoints.DELIVERY_TERMS,
        weight: 9,
        returnFunction: (data, props) => {
            props.setDeliveryTerms(data.Results);
        }
    },
    {
        name: 'unitsOfMeasure',
        endpoint: APIEndPoints.UNITOFMEASURE,
        weight: 224,
        returnFunction: (data, props) => {
            props.setUnitsOfMeasurements(data.Results);
        }
    },
    {
        name: 'Poolweek',
        endpoint: APIEndPoints.POOLWEEK,
        weight: 10,
        returnFunction: (data, props) => {
            props.setPoolWeeks(data.Results);
        }
    },
    {
        name: 'OBO',
        endpoint: APIEndPoints.OBO,
        weight: 10,
        returnFunction: (data, props) => {
            props.setOBOs(data.Results);
        }
    }
];

var getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
    var apiCalls = [];

    masterDataCalls.forEach((call) => {
        var middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);

        middlewareCall.cacheLifespanInMinutes = DEFAULT_CACHE_DURATION_IN_MINUTES;

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, call.weight, (data) => {
                call.returnFunction(data, props);
            })
        );
    });

    Middleware.SendMultiple('Initialize', apiCalls, statusUpdate)
        .then((message) => {
            lastMasterDataLoadTime = Date.now();
            onSuccess(message);
            hasLoggedMasterDataInfo = false;
            hasLoggedMasterDataWarning = false;
        })
        .catch((error) => {
            lastMasterDataLoadTime = Date.now();
            onError(error);
        });
};

var checkMasterData = (isMasterDataLoaded) => {
    if (!isMasterDataLoaded) {
        return false;
    }

    if (lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() && !hasLoggedMasterDataInfo) {
        hasLoggedMasterDataInfo = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
                2,
                '/InitializeApplication/'
            )
        );
    }

    if (lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() && !hasLoggedMasterDataWarning) {
        hasLoggedMasterDataWarning = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
                3,
                '/InitializeApplication/'
            )
        );
    }

    return true;
};

var redirectToLoadMasterData = () => {
    redirectLocationReducerFunction(window.location.pathname);
    return <Redirect to="/InitializeApplication/" />;
};

var registerMasterDataReducerVariables = (setMasterDataRedirectLocation, setMasterDataInitialized) => {
    redirectLocationReducerFunction = setMasterDataRedirectLocation;
    //setMasterDataInitialized = setMasterDataInitialized;
};

const MasterDataUtilities = {
    Load: getMasterData,
    Check: checkMasterData,
    Redirect: redirectToLoadMasterData,
    Register: registerMasterDataReducerVariables
};

export default MasterDataUtilities;
