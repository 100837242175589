import { Middleware } from 'one-ring';

const SoDetailsMapping = [
    Middleware.CreateMapping('SOConversion', 'SOConversion'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('MaxAssignedId', 'MaxAssignedId'),
    Middleware.CreateMapping('Header', 'Header'),
    Middleware.CreateMapping('POLineHeader', 'POLineHeader'),
    Middleware.CreateMapping('ItemDescription', 'ItemDescription'),
    Middleware.CreateMapping('QuantityOrdered', 'QuantityOrdered'),
    Middleware.CreateMapping('WarehouseNbr', 'WarehouseNbr'),
    Middleware.CreateMapping('POHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('POLineId', 'CustPOLineId'),
    Middleware.CreateMapping('CustomerRequestedPrice', 'CustomerRequestedPrice'),
    Middleware.CreateMapping('DriscollsItemNbr', 'DriscollsItemNbr'),
    Middleware.CreateMapping('PONumber', 'PONumber'),
    Middleware.CreateMapping('RequestedReceiptDate', 'RequestedReceiptDate'),
    Middleware.CreateMapping('OCNumber', 'OCNumber'),
    Middleware.CreateMapping('OCNumber', 'OCNumber'),
    Middleware.CreateMapping('ExpectedShipDate', 'ExpectedShipDate'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('ItemDescription', 'ItemDescription'),
    Middleware.CreateMapping('LineOBO', 'LineOBO'),
    Middleware.CreateMapping('UnitPrice', 'UnitPrice'),
    Middleware.CreateMapping('LoadedPrice', 'LoadedPrice'),
    Middleware.CreateMapping('QtyOriginal', 'QtyOriginal'),
    Middleware.CreateMapping('DriscollVolAvail', 'DriscollVolAvail'),
    Middleware.CreateMapping('SalesQuantity', 'SalesQuantity'),
    Middleware.CreateMapping('Balance', 'Balance'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('LineRecId', 'LineRecId'),
    Middleware.CreateMapping('Company', 'Company'),
    Middleware.CreateMapping('SmileStatus', 'SmileStatus'),
    Middleware.CreateMapping('Items', 'Items'),
    Middleware.CreateMapping('SalesUpdatedBy', 'SalesUpdatedBy'),
    Middleware.CreateMapping('MatchTypeUpdatedBy', 'MatchTypeUpdatedBy'),
    Middleware.CreateMapping('MatchTypeStatus', 'MatchTypeStatus'),
    Middleware.CreateMapping('SellToName', 'SellToName'),
    Middleware.CreateMapping('SalesOrderStatus', 'SalesOrderStatus'),
    Middleware.CreateMapping('TransControlNbr', 'TransControlNbr'),
    Middleware.CreateMapping('MatchTypePeriod', 'MatchTypePeriod'),
    Middleware.CreateMapping('SalesUpdatedDateTime', 'SalesUpdatedDateTime'),
    Middleware.CreateMapping('TransactionType', 'TransactionType'),
    Middleware.CreateMapping('CustDlvTerm', 'CustDlvTerm'),
    Middleware.CreateMapping('MatchTypeUpdatedDateTime', 'MatchTypeUpdatedDateTime'),
    Middleware.CreateMapping('LoadEndDate', 'LoadEndDate'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('CustPOHeaderId', 'CustPOHeaderId'),
    Middleware.CreateMapping('CustPOLineId', 'CustPOLineId'),
    Middleware.CreateMapping('DeliveryTerm', 'DeliveryTerm'),
    Middleware.CreateMapping('CustomerRef', 'CustomerRef'),
    Middleware.CreateMapping('MatchType', 'MatchType'),
    Middleware.CreateMapping('MatchTypeHeaderRecId', 'MatchTypeHeaderRecId'),
    Middleware.CreateMapping('CustPOStatus', 'CustPOStatus'),
    Middleware.CreateMapping('company', 'company'),
    Middleware.CreateMapping('MatchTypeCreatedDateTime', 'MatchTypeCreatedDateTime'),
    Middleware.CreateMapping('SalesHeaderRecVersion', 'SalesHeaderRecVersion'),
    Middleware.CreateMapping('ShExpectedShipDate', 'ShExpectedShipDate'),
    Middleware.CreateMapping('SalesTakerId', 'SalesTakerId'),
    Middleware.CreateMapping('SalesCreatedDateTime', 'SalesCreatedDateTime'),
    Middleware.CreateMapping('SalesOrderRef', 'SalesOrderRef'),
    Middleware.CreateMapping('ModeOfDelivery', 'ModeOfDelivery'),
    Middleware.CreateMapping('SalesHeaderRecId', 'SalesHeaderRecId'),
    Middleware.CreateMapping('LoadStartDate', 'LoadStartDate'),
    Middleware.CreateMapping('SalesCreatedBy', 'SalesCreatedBy'),
    Middleware.CreateMapping('DriscollsPaymentTerm', 'DriscollsPaymentTerm'),
    Middleware.CreateMapping('InvoiceAccount', 'InvoiceAccount'),
    Middleware.CreateMapping('MarketProgram', 'MarketProgram'),
    Middleware.CreateMapping('DriscollsOrderLock', 'DriscollsOrderLock'),
    Middleware.CreateMapping('MatchTypeHeaderRecVersion', 'MatchTypeHeaderRecVersion'),
    Middleware.CreateMapping('SalesPersonId', 'SalesPersonId'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('MatchTypeId', 'MatchTypeId'),
    Middleware.CreateMapping('ShipToAccount', 'ShipToAccount'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateMapping('RequestedReceiptDate', 'RequestedReceiptDate'),
    Middleware.CreateArrayMapping('Results', 'SOConversion'),
    Middleware.CreateMapping('ItemType', 'ItemType')
];

const SoConversionPostMapping = [
    Middleware.CreateMapping('POHeaderId', 'POHeaderId'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('MatchTypeLineNbr', 'MatchTypeLineNbr'),
    Middleware.CreateMapping('MatchTypeId', 'MatchTypeId'),
    Middleware.CreateMapping('MatchTypeStatus', 'MatchTypeStatus'),
    Middleware.CreateMapping('MatchTypePeriod', 'MatchTypePeriod'),
    Middleware.CreateMapping('LoadStartDate', 'LoadStartDate'),
    Middleware.CreateMapping('LoadEndDate', 'LoadEndDate'),
    Middleware.CreateMapping('MarketProgram', 'MarketProgram'),
    Middleware.CreateMapping('SellToName', 'SellToName'),
    Middleware.CreateMapping('SalesOrderStatus', 'SalesOrderStatus'),
    Middleware.CreateMapping('DriscollsOrderLock', 'DriscollsOrderLock'),
    Middleware.CreateMapping('ShipToAccount', 'ShipToAccount'),
    Middleware.CreateMapping('InvoiceAccount', 'InvoiceAccount'),
    Middleware.CreateMapping('ShipDateRequested', 'ShipDateRequested'),
    Middleware.CreateMapping('ReceiptDateRequested', 'ReceiptDateRequested'),
    Middleware.CreateMapping('Warehouse', 'Warehouse'),
    Middleware.CreateMapping('DlvMode', 'DlvMode'),
    Middleware.CreateMapping('DlvTerm', 'DlvTerm'),
    Middleware.CreateMapping('DriscollPaymTerm', 'DriscollPaymTerm'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('SalesTaker', 'SalesTaker'),
    Middleware.CreateMapping('SalesResponsible', 'SalesResponsible'),
    Middleware.CreateMapping('MatchTypeCreatedDateTime', 'MatchTypeCreatedDateTime'),
    Middleware.CreateMapping('MatchTypeCreatedBy', 'MatchTypeCreatedBy'),
    Middleware.CreateMapping('MatchTypeUpdatedDateTime', 'MatchTypeUpdatedDateTime'),
    Middleware.CreateMapping('MatchTypeUpdatedBy', 'MatchTypeUpdateddBy'),
    Middleware.CreateMapping('MatchTypeUpdatedBy', 'MatchTypeUpdatedBy'),
    Middleware.CreateMapping('SalesCreatedDateTime', 'SalesCreatedDateTime'),
    Middleware.CreateMapping('SalesCreatedBy', 'SalesCreatedBy'),
    Middleware.CreateMapping('SalesUpdatedDateTime', 'SalesUpdatedDateTime'),
    Middleware.CreateMapping('SalesUpdatedBy', 'SalesUpdatedBy'),
    Middleware.CreateMapping('Company', 'Company'),
    Middleware.CreateMapping('MatchTypeHeaderRecVersion', 'MatchTypeHeaderRecVersion'),
    Middleware.CreateMapping('MatchTypeHeaderRecId', 'MatchTypeHeaderRecId'),
    Middleware.CreateMapping('SalesHeaderRecVersion', 'SalesHeaderRecVersion'),
    Middleware.CreateMapping('SalesHeaderRecId', 'SalesHeaderRecId'),
    Middleware.CreateMapping('POLineId', 'POLineId'),
    Middleware.CreateMapping('SalesOrderNbr', 'SalesOrderNbr'),
    Middleware.CreateMapping('MatchTypeLineNbr', 'MatchTypeLineNbr'),
    Middleware.CreateMapping('SalesLineNbr', 'SalesLineNbr'),
    Middleware.CreateMapping('ItemNbr', 'ItemNbr'),
    Middleware.CreateMapping('QtyOrdered', 'QtyOrdered'),
    Middleware.CreateMapping('DriscollQtyOrig', 'DriscollQtyOrig'),
    Middleware.CreateMapping('DriscollVolAvail', 'DriscollVolAvail'),
    Middleware.CreateMapping('SalesType', 'SalesType'),
    Middleware.CreateMapping('SalesUnit', 'SalesUnit'),
    Middleware.CreateMapping('SalesPrice', 'SalesPrice'),
    Middleware.CreateMapping('UnitPrice', 'UnitPrice'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('DriscollPD', 'DriscollPD'),
    Middleware.CreateMapping('SalesLineStatus', 'SalesLineStatus'),
    Middleware.CreateMapping('BerrySalesPlanGroup', 'BerrySalesPlanGroup'),
    Middleware.CreateMapping('MatchTypeLineRecVersion', 'MatchTypeLineRecVersion'),
    Middleware.CreateMapping('MatchTypeLineRecId', 'MatchTypeLineRecId'),
    Middleware.CreateMapping('SalesLineRecVersion', 'SalesLineRecVersion'),
    Middleware.CreateMapping('SalesLineRecId', 'SalesLineRecId'),
    Middleware.CreateMapping('SalesLineCreatedDateTime', 'SalesLineCreatedDateTime'),
    Middleware.CreateMapping('SalesLineCreatedBy', 'SalesLineCreatedBy'),
    Middleware.CreateMapping('SalesLineUpdatedDateTime', 'SalesLineUpdatedDateTime'),
    Middleware.CreateMapping('SalesLineUpdatedBy', 'SalesLineUpdatedBy'),
    Middleware.CreateMapping('TransactionType', 'TransactionType'),
    Middleware.CreateMapping('MatchType', 'MatchType'),
    Middleware.CreateMapping('TransCtrlNbr', 'TransCtrlNbr'),
    Middleware.CreateMapping('CustomerRef', 'CustomerRef'),
    Middleware.CreateMapping('CustPOStatus', 'CustPOStatus'),
    Middleware.CreateMapping('CustDlvTerm', 'CustDlvTerm'),
    Middleware.CreateMapping('SalesOrderRef', 'SalesOrderRef'),
    Middleware.CreateMapping('Header', 'Header'),
    Middleware.CreateMapping('Lines', 'Lines'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('Checked', 'Checked'),
    Middleware.CreateMapping('Date', 'Date')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SODetails', 'GET')) {
        Middleware.RegisterMapping('SODetails', 'GET', SoDetailsMapping, true);
    }
    if (!Middleware.CheckMappingExists('SOConversion', 'POST')) {
        Middleware.RegisterMapping('SOConversion', 'POST', SoConversionPostMapping, true);
    }
}

const SoConversion = {
    ConfigureMiddleware
};

export default SoConversion;
