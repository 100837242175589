//BEGIN - paymentlist data
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const setPaymnetList = (paymentData, clear) => ({
    type: SET_PAYMENT_LIST,
    payload: { paymentData, clear }
});
export const SET_PARTICULAR_ROW = 'SET_PARTICULAR_ROW';
export const setParticularRow = (row) => ({
    type: SET_PARTICULAR_ROW,
    payload: { row }
});

//END - paymentlist data
//BEGIN - Dialogs

export const OPEN_CLOSE_PAYMENT_DETAILS = 'OPEN_CLOSE_PAYMENT_DETAILS';
export const openClosePaymentDetails = (open, paymentData, lineItems) => ({
    type: OPEN_CLOSE_PAYMENT_DETAILS,
    payload: { open, paymentData }
});

//END - Dialogs
//BEGIN - Filters

export const SET_PAYMENT_FILTERS = 'SET_PAYMENT_FILTERS';
export const setPaymentFiltersAction = (name, option) => ({
    type: SET_PAYMENT_FILTERS,
    payload: {
        name,
        option
    }
});

export const CLEAR_PAYMENT_FILTERS = 'CLEAR_PAYMENT_FILTERS';
export const clearPaymentFiltersAction = () => ({
    type: CLEAR_PAYMENT_FILTERS,
    payload: {}
});

export const CLEAR_ONE_PAYMENT_FILTER = 'CLEAR_ONE_PAYMENT_FILTER';
export const clearOnePaymentFilter = (filter) => ({
    type: CLEAR_ONE_PAYMENT_FILTER,
    payload: { filter }
});

export const SET_ALL_PAYMENT_FILTERS = 'SET_ALL_PAYMENT_FILTERS';
export const setAllPaymentFilters = (filters) => ({
    type: SET_ALL_PAYMENT_FILTERS,
    payload: { filters }
});

//END - Filters
