import React from 'react';
import { DrcButton, DrcDialog, DrcGrid, DrcTooltip, DrcKeyValueTable } from 'driscolls-react-components';
import { withAuth } from '@okta/okta-react';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import APIEndPoints from 'services/api';
import { Middleware } from 'one-ring';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setSavedFiltersAndGridSettings, setErrorsAction } from '../../actions/actions';
import FilterUtilities from 'data/FilterUtilities';
import SearchUtilities from 'data/SearchUtilities';
import { clearSelectedPo, setSelectedPo, setPoList, setAllFilters, clearPoFiltersAction } from 'actions/PoListActions';
import DataUtilities from 'data/DataUtilities';
import createHistory from 'history/createBrowserHistory';
import PoFilterNames from 'services/PoFilterNames';

const history = createHistory();

var styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'calc(100% - 50px)'
        },
        '& .MuiDialogTitle-root': {
            borderBottom: 'none !important',
            marginBottom: 0
        },
        '& .MuiDialogActions-root': {
            borderTop: 'none !important',
            marginTop: 0
        },
        '& .MuiDialogActions-root > div': {
            minWidth: '567px',
            margin: '-8px 0'
        },
        '& .MuiTypography-h6': {
            fontSize: '2rem'
        }
    },
    noMarginLeft: { marginLeft: '0 !important' },
    noMarginRight: { marginRight: '0 !important' }
});

class SavedFiltersDialog extends React.Component {
    columns = [
        {
            key: 'Actions',
            name: (
                <DrcTooltip tipText="Actions">
                    <span>Actions</span>
                </DrcTooltip>
            ),
            width: 120,
            resizeable: true
        },
        {
            key: 'FilterName',
            name: (
                <DrcTooltip tipText="Filter Name">
                    <span>Filter Name</span>
                </DrcTooltip>
            ),
            width: 300
        },
        {
            key: 'CreatedDateTime',
            name: (
                <DrcTooltip tipText="Created On">
                    <span>Created On</span>
                </DrcTooltip>
            ),
            width: 130
        }
    ];
    constructor(props) {
        super(props);

        this.state = { currentRow: {}, filterKeyValues: [] };
        this.variableOptions = [
            { value: PoFilterNames.BERRY_TYPE, label: 'Berry Type', inputType: 'berrySelect' },
            { value: PoFilterNames.BERRY_FIELD_TYPE, label: 'BerryFieldType', inputType: 'select' },
            { value: PoFilterNames.TYPE_OF_CHANGE, label: 'Change Type', inputType: 'select' },
            { value: PoFilterNames.CHANNEL, label: 'Channel', inputType: 'select' },
            { value: PoFilterNames.DELIVERY_REQUESTED, label: 'Requested Receipt Date', inputType: 'date' },
            { value: PoFilterNames.MODE_OF_DELIVERY, label: 'Dlv Mode', inputType: 'select' },
            { value: PoFilterNames.EMPLOYEE_RESPONSIBLE, label: 'Employee Responsible', inputType: 'select' },
            { value: PoFilterNames.INVOICE_ACCOUNT_NUMBER, label: 'Invoice Account Number', inputType: 'select' },
            {
                value: PoFilterNames.ITEM_NUMBER,
                label: 'Item Number',
                inputType: 'select',
                dependencies: [PoFilterNames.INVOICE_ACCOUNT_NAME, PoFilterNames.BERRY_TYPE],
                dataprovider: this.getItems
            },
            { value: PoFilterNames.PO_NUMBER, label: 'PO Number', inputType: 'text' },
            { value: PoFilterNames.RWH, label: 'RWH', inputType: 'select' },
            { value: PoFilterNames.SALESREP, label: 'Sales Taker', inputType: 'select' },
            { value: PoFilterNames.SHIPMENTREQUESTED, label: 'Shipment Requested Date', inputType: 'date' },
            { value: PoFilterNames.SHIPTO, label: 'Ship To', inputType: 'select' },
            { value: PoFilterNames.STATUS, label: 'Status', inputType: 'select' },
            { value: PoFilterNames.WAREHOUSE, label: 'Warehouse', inputType: 'select' },
            { value: PoFilterNames.POOLWEEK, label: 'PO Receipt Week', inputType: 'select' },
            { value: PoFilterNames.PO_TYPE, label: 'PO Type', inputType: 'select' }
        ];
    }
    getCellActions = (column, row) => {
        const searchAction = {
            icon: (
                <DrcButton
                    to={'/PurchaseOrders/Search/' + row.FilterUrl}
                    size="small"
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 8px 0'
                    }}
                >
                    <SearchIcon />
                </DrcButton>
            ),
            callback: () => {
                this.props.clearPoFilters();
                var encodedUrl = this.makefilterObjectFromUrl(row.FilterUrl);
                var filters = JSON.parse(decodeURI(atob(encodedUrl)));
                var filterKeyValues = {};
                var filterArray = [];
                Object.keys(filters).forEach((k) => {
                    filterKeyValues[k] = filters[k];
                    filterArray.push({ [k]: { value: filters[k] }, isSet: true });
                });
                FilterUtilities.DecodeFilterUrl(
                    filterArray,
                    { Criteria: encodedUrl },
                    this.props.masterData,
                    this.props.setAllFilters,
                    this.props.clearPoFilters
                );
                FilterUtilities.EncodeFilterUrl(filterArray, history);
                this.props.hideFilterDialog();
                setTimeout(() => this.props.setPoList([], true, {}), 0);
                //https://driscollsit.atlassian.net/browse/PE-937
                this.props.auth.getAccessToken().then((token) => {
                    Middleware.Send('PoList', token, APIEndPoints.PO_LIST_SEARCH + SearchUtilities.GetQueryString(), 'POST', filterKeyValues, {
                        overrideRequestMapping: true
                    })
                        .then((data) => {
                            this.props.setPoList(data, true, this.props.salesRepOptions);
                            DataUtilities.ForceUpdatePoList();
                        })
                        .catch((e) => {
                            this.props.setErrorsAction('Error!', 'Please try again.');
                        });

                    this.props.clearSelectedPo();
                    this.props.hideFilterDialog();
                });
            }
        };
        const deleteAction = {
            icon: (
                <DrcButton
                    size="small"
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 0 0'
                    }}
                >
                    <DeleteIcon />
                </DrcButton>
            ),
            callback: async () => {
                var loggedInUser;
                var token = await this.props.auth.getAccessToken();
                loggedInUser = await DuAuthenticationUtilities.GetEmail(token);
                var deletedFilterSettings = this.props.filters.filter((filter) => {
                    return filter.FilterName !== row.FilterName;
                });

                var payload = {
                    CreatedBy: 'SYSTEM',
                    LastUpdatedBy: loggedInUser,
                    LastUpdatedDateTime: new Date().toISOString(),
                    CreatedDateTime: new Date().toISOString(),
                    Email: loggedInUser,
                    FilterSetting: deletedFilterSettings
                };

                this.props.auth.getAccessToken().then((token) => {
                    Middleware.Send('savedFiltersAndGridSettings', token, APIEndPoints.ADD_USER_FILTER, 'POST', payload, {}, {})
                        .then(() => {
                            Middleware.Send(
                                'savedFiltersAndGridSettings',
                                token,
                                APIEndPoints.GET_USER_FILTERS_AND_GRID_SETTINGS(loggedInUser),
                                'GET',
                                {},
                                {}
                            ).then((data) => {
                                this.props.setSavedFiltersAndGridSettings(data.Results);
                            });
                        })
                        .catch((e) => {
                            this.props.setErrorsAction('Error!', 'Please try again.');
                        });
                });
                // this.props.hideFilterDialog();
                // TODO: Determine if its better to close the filter Dialog or not, i personally feel user may want to delete another so I keept it open for now
            }
        };
        const detailsAction = {
            icon: (
                <DrcButton
                    size="small"
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 8px 0'
                    }}
                >
                    <MoreHoriz />
                </DrcButton>
            ),
            callback: () => {
                var filterKeyValues = [];
                var filters = JSON.parse(decodeURI(atob(this.makefilterObjectFromUrl(row.FilterUrl))));

                Object.keys(filters).forEach((k) => {
                    if (k === 'DeliveryRequested') {
                        filterKeyValues.push({
                            key: 'Requested Receipt Start Date',
                            value: DuDateUtilities.FormatDateFromIso(filters[k]['StartDate'])
                        });
                        filterKeyValues.push({ key: 'Requested Receipt End Date', value: DuDateUtilities.FormatDateFromIso(filters[k]['EndDate']) });
                    } else if (k === 'ShipmentRequested') {
                        filterKeyValues.push({
                            key: 'Shipment Requested Start Date',
                            value: DuDateUtilities.FormatDateFromIso(filters[k]['StartDate'])
                        });
                        filterKeyValues.push({ key: 'Shipment Requested End Date', value: DuDateUtilities.FormatDateFromIso(filters[k]['EndDate']) });
                    } else if (k === 'SalesRep') {
                        this.props.salesRepOptions.forEach((option) => {
                            if (option.value === filters[k]) {
                                filterKeyValues.push({ key: 'Sales Taker', value: option.label });
                            }
                        });
                    } else if (k === 'BerryType') {
                        this.props.BerryTypeOptions.forEach((option) => {
                            if (parseInt(option.value) === parseInt(filters[k])) {
                                filterKeyValues.push({ key: 'Berry Type', value: option.label });
                            }
                        });
                    } else if (k === 'InvoiceAccountNumber') {
                        this.props.customerOptions.forEach((option) => {
                            if (option.value === filters[k]) {
                                filterKeyValues.push({ key: 'Invoice Account Name', value: option.label });
                            }
                        });
                    } else {
                        this.variableOptions.forEach((option) => {
                            if (k === option.value) {
                                filterKeyValues.push({ key: option.label, value: filters[k] });
                            }
                        }); //filterKeyValues.push({ key: k.split(/(?=[A-Z])/).join(' '), value: filters[k] });
                    } //adding spcae between words in camel casing
                });

                this.setState({ showFilterDetails: true, currentRow: row, filterKeyValues });
            }
        };

        const cellActions = {
            Actions: [deleteAction, detailsAction, searchAction]
        };
        return cellActions[column.key];
    };
    makefilterObjectFromUrl = (url) => {
        if (url[url.length - 1] === '/') {
            return url.substring(0, url.length - 1);
        } else return url;
    };
    render() {
        return (
            <div>
                <DrcDialog
                    className={this.props.classes.dialog}
                    fullActionWrapper={true}
                    fullWidth
                    title="Saved Searches"
                    open={true}
                    buttons={
                        <div style={{ float: 'right', marginRight: -8 }}>
                            <DrcButton title="Cancel" isPrimary onClick={() => this.props.hideFilterDialog()}>
                                Close
                            </DrcButton>
                        </div>
                    }
                    onClose={() => this.props.hideFilterDialog()}
                >
                    <DrcGrid
                        getCellActions={this.getCellActions}
                        hideCount
                        columns={this.columns}
                        rows={this.props.filters.map((filter) => {
                            //Just to format date
                            return {
                                FilterName: filter.FilterName,
                                FilterUrl: filter.FilterUrl,
                                CreatedDateTime: DuDateUtilities.FormatDateFromIso(filter.CreatedDateTime)
                            };
                        })}
                        resizable={true}
                        rowHeight={35}
                        headerRowHeight={35}
                        minHeight={300}
                        autoHeight={true}
                    />
                </DrcDialog>
                <DrcDialog
                    open={this.state.showFilterDetails}
                    title={'Filter Details'}
                    buttons={
                        <div style={{ float: 'right', marginRight: -8 }}>
                            <DrcButton title="Cancel" isPrimary onClick={() => this.setState({ showFilterDetails: false, filterKeyValues: [] })}>
                                Close
                            </DrcButton>
                        </div>
                    }
                    onClose={() => this.setState({ showFilterDetails: false })}
                >
                    <DrcKeyValueTable>{this.state.filterKeyValues}</DrcKeyValueTable>
                </DrcDialog>
            </div>
        );
    }
}
var getFiltersFromReducer = (filterSetting) => {
    try {
        var parsed = JSON.parse(filterSetting);
        if (parsed === null) {
            return [];
        } else {
            return parsed;
        }
    } catch (error) {
        return [];
    }
};
function mapStateToProps(state) {
    return {
        filters: getFiltersFromReducer(state.filterReducer.filterResult.FilterSetting),
        filterResult: state.filterReducer.filterResult,
        masterData: state.masterReducer,
        customerOptions: state.masterReducer.customerOptions,
        salesRepOptions: state.masterReducer.salesRepOptions,
        BerryTypeOptions: state.masterReducer.berryTypeOptions
    };
}
const mapDispatchToProps = (dispatch) => ({
    setSavedFiltersAndGridSettings: (filters) => dispatch(setSavedFiltersAndGridSettings(filters)),
    clearSelectedPo: () => dispatch(clearSelectedPo()),
    setSelectedPo: (poRef, isSelected) => dispatch(setSelectedPo(poRef, isSelected)),
    setPoList: (data, clear, salesReps) => dispatch(setPoList(data, clear, salesReps)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors)),
    clearPoFilters: () => dispatch(clearPoFiltersAction()),
    setAllFilters: (filters) => dispatch(setAllFilters(filters))
});
export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(SavedFiltersDialog)));
