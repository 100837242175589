import { Middleware } from 'one-ring';

const MaintenanceMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('CriteriaId', 'CriteriaId'),
    Middleware.CreateMapping('CriteriaName', 'CriteriaName'),
    Middleware.CreateMapping('CriteriaOptions', 'CriteriaOptions'),
    Middleware.CreateMapping('CriteriaOrder', 'CriteriaOrder'),
    Middleware.CreateArrayMapping('Results', 'list')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Maintenance', 'GET')) {
        Middleware.RegisterMapping('Maintenance', 'GET', MaintenanceMappings);
    }
}

const Maintenance = {
    ConfigureMiddleware
};

export default Maintenance;
