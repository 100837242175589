import { Middleware } from 'one-ring';

const paymentTermsMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('PaymTermCode', 'PaymTermCode'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('CustomerId', 'CustomerId'),
    Middleware.CreateMapping('CustomerTerms', 'CustomerTerms'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('UpdatedBy', 'UpdatedBy'),
    Middleware.CreateMapping('UpdatedDateTime', 'UpdatedDateTime'),
    Middleware.CreateMapping('Enabled', 'Enabled'),
    Middleware.CreateArrayMapping('Results') //array name change
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PaymentTerms', 'GET')) {
        Middleware.RegisterMapping('PaymentTerms', 'GET', paymentTermsMappings);
        Middleware.RegisterMapping('PaymentTerms', 'POST', paymentTermsMappings);
        Middleware.RegisterMapping('PaymentTerms', 'DELETE', paymentTermsMappings);
    }
}

const paymentTerms = {
    ConfigureMiddleware
};

export default paymentTerms;
