export const SERVICE_WORKER_UPDATE = 'SERVICE_WORKER_UPDATE';

export const SET_ERRORS = 'SET_ERRORS';
export const setErrorsAction = (title, errors) => ({
    type: SET_ERRORS,
    payload: { title, errors }
});

export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';
export const hideErrorDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const showLoadingScreenAction = (title) => ({
    type: SHOW_LOADING_SCREEN,
    payload: { title }
});

export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';
export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

export const CLEAR_INFO = 'CLEAR_INFO';
export const clearInfo = (name) => ({
    type: CLEAR_INFO,
    payload: name
});

export const SET_SAVED_FILTERS_AND_GRID_SETTINGS = 'SET_SAVED_FILTERS_AND_GRID_SETTINGS';
export const setSavedFiltersAndGridSettings = (userSettings) => ({ type: SET_SAVED_FILTERS_AND_GRID_SETTINGS, payload: userSettings });

export const HIDE_SAVED_FILTERS_ICON = 'HIDE_SAVED_FILTERS_ICON';
export const hideSavedFilterIcon = (hide) => ({ type: HIDE_SAVED_FILTERS_ICON, payload: hide });

export const SET_GRID_SETTINGS_ORDER = 'SET_GRID_SETTINGS_ORDER';
export const setGridSettingsOrder = (columnSourceIndex, columnTargetIndex) => ({
    type: SET_GRID_SETTINGS_ORDER,
    payload: { columnSourceIndex, columnTargetIndex }
});

export const SET_GRID_COLUMN_TOGGLE = 'SET_GRID_COLUMN_TOGGLE';
export const setGridColumnVisible = (targetColumn) => ({ type: SET_GRID_COLUMN_TOGGLE, payload: { targetColumn } });

export const TOGGLE_COLUMN_LIST = 'TOGGLE_COLUMN_LIST';
export const toggleColumnBar = (showColumnBar) => ({ type: TOGGLE_COLUMN_LIST, payload: showColumnBar });

export const TOGGLE_GRID_SETTINGS = 'TOGGLE_GRID_SETTINGS';
export const toggleGridSettings = (showGridSettings) => ({ type: TOGGLE_GRID_SETTINGS, payload: showGridSettings });

export const RESET_GRID = 'RESET_GRID';
export const resetGridSettings = () => ({ type: RESET_GRID, payload: {} });

export const SERVICE_WORKER_UPDATED = 'SERVICE_WORKER_UPDATED';
export const serviceWorkerUpdate = () => ({
    type: SERVICE_WORKER_UPDATED
});

export const SHOW_TOAST = 'SHOW_TOAST';
export const showToast = (toastMessage, toastType) => ({
    type: SHOW_TOAST,
    payload: { toastMessage, toastType }
});

