import { OPEN_CLOSE_PAYMENT_DETAILS, SET_PAYMENT_LIST, SET_PARTICULAR_ROW } from 'actions/PaymentListActions';
import { DuDateUtilities } from 'driscolls-react-utilities';
import SearchUtilities from 'data/SearchUtilities';

const initialState = {
    paymentDialogOpen: false,
    paymentData: [],
    headerData: {},
    lineItems: [],
    paymentDataRecordsCount: 0,
    paymentRecordStatus: [
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Created', value: 'Created' },
        { label: 'Posted', value: 'Posted' },
        { label: 'Processed', value: 'Processed' },
        { label: 'Reversed', value: 'Reversed' },
        { label: 'Submitted', value: 'Submitted' }
    ],
    currencyCodeOptions: [
        { label: 'USD', value: 'USD' },
        { label: 'INR', value: 'INR' }
    ]
};

const paymentListReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_CLOSE_PAYMENT_DETAILS:
            if (!action.payload.paymentData) {
                return state;
            }
            return Object.assign({}, state, {
                paymentDialogOpen: action.payload.open,
                headerData: action.payload.paymentData.headerData,
                lineItems: action.payload.paymentData.lineItems
            });
        case SET_PAYMENT_LIST:
            if (!action.payload.paymentData) {
                return state;
            }
            return Object.assign({}, state, {
                paymentData: action.payload.paymentData.payments,
                paymentDataRecordsCount: action.payload.paymentData.TotalCount || state.paymentDataRecordsCount
            });
        case SET_PARTICULAR_ROW:
            if (!action.payload.row) {
                return state;
            }
            let { paymentData } = state;
            let rowToInsert = action.payload.row;
            let mutatedPaymentData = paymentData.map((paymentLine) => {
                if (paymentLine.PaymentReference === rowToInsert.PaymentReference) {
                    return rowToInsert;
                } else return paymentLine;
            });
            return Object.assign({}, state, {
                paymentData: mutatedPaymentData
            });
        default:
            return state;
    }
};

export default paymentListReducer;
