export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const setIsAdmin = (data) => ({
    type: SET_IS_ADMIN,
    payload: data
});

export const STATUSES = 'STATUSES';
export const setStatuses = (statuses) => ({
    type: STATUSES,
    payload: statuses
});

export const WAREHOUSES = 'WAREHOUSES';
export const setWarehouses = (warehouses) => ({
    type: WAREHOUSES,
    payload: warehouses
});

export const BERRY_TYPES = 'BERRY_TYPES';
export const setBerryTypes = (berryTypes) => ({
    type: BERRY_TYPES,
    payload: berryTypes
});

export const DELIVERY_MODES = 'DELIVERY_MODES';
export const setDeliveryModes = (deliveryModes) => ({
    type: DELIVERY_MODES,
    payload: deliveryModes
});

export const CUSTOMERS = 'CUSTOMERS';
export const setCustomers = (customers) => ({
    type: CUSTOMERS,
    payload: customers
});

export const RECEIVING_WAREHOUSES = 'RECEIVING_WAREHOUSES';
export const setReceivingWarehouses = (receivingWarehouses) => ({
    type: RECEIVING_WAREHOUSES,
    payload: receivingWarehouses
});

export const CHANGE_TYPES = 'CHANGE_TYPES';
export const setChangeTypes = (changeTypes) => ({
    type: CHANGE_TYPES,
    payload: changeTypes
});

export const CHANNELS = 'CHANNELS';
export const setChannels = (channels) => ({
    type: CHANNELS,
    payload: channels
});

export const SALES_REPS = 'SALES_REPS';
export const setSalesReps = (salesReps) => ({
    type: SALES_REPS,
    payload: salesReps
});

export const PAYMENT_TERMS = 'PAYMENT_TERMS';
export const setPaymentTerms = (paymentTerms) => ({
    type: PAYMENT_TERMS,
    payload: paymentTerms
});
export const FSD = 'FSD';
export const setFSD = (fsd) => ({
    type: FSD,
    payload: fsd
});

export const DELIVERY_TERMS = 'DELIVERY_TERMS';
export const setDeliveryTerms = (deliveryTerms) => ({
    type: DELIVERY_TERMS,
    payload: deliveryTerms
});

export const CUSTOMER_DELIVERY_TERMS = 'CUSTOMER_DELIVERY_TERMS';
export const setCustomerDeliveryTerms = (customerDeliveryTerms) => ({
    type: CUSTOMER_DELIVERY_TERMS,
    payload: customerDeliveryTerms
});

export const UNIT_OF_MEASUREMENTS = 'UNIT_OF_MEASUREMENTS';
export const setUnitsOfMeasurements = (unitsOfMeasurements) => ({
    type: UNIT_OF_MEASUREMENTS,
    payload: unitsOfMeasurements
});

export const POOLWEEK = 'POOLWEEK';
export const setPoolWeeks = (poolweeks) => ({
    type: POOLWEEK,
    payload: poolweeks
});

export const OBO = 'OBO';
export const setOBOs = (OBOs) => ({
    type: OBO,
    payload: OBOs
});
