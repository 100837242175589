import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from './data/store';
import App from './App';
import Middleware from './data/middleware';
import { DrcThemeProvider } from 'driscolls-react-components';
import { SERVICE_WORKER_UPDATE } from './actions/actions';
import * as serviceWorker from './serviceWorker';

const renderApp = () =>
    render(
        <Provider store={Store}>
            <DrcThemeProvider>
                <App />
            </DrcThemeProvider>
        </Provider>,
        document.getElementById('root')
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

Middleware.ConfigureAllMiddleware();

const serviceWorkerConfig = {
    onUpdate: (reg) => Store.dispatch({ type: SERVICE_WORKER_UPDATE, payload: reg })
};

serviceWorker.register(serviceWorkerConfig);

renderApp();
