import { Middleware } from 'one-ring';

const ProfileMappings = [
    Middleware.CreateMapping('PoNotifications', 'PoNotifications'),
    Middleware.CreateMapping('SoNotifications', 'SoNotifications'),
    Middleware.CreateMapping('ErrorNotifications', 'ErrorNotifications'),
    Middleware.CreateMapping('DarkMode', 'DarkMode')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserProfile', 'GET')) {
        Middleware.RegisterMapping('UserProfile', 'GET', ProfileMappings);
    }
}

const Profile = {
    ConfigureMiddleware
};

export default Profile;
