import Statuses from '../services/POStatus';

var convertToCurrency = (priceString, fallback = '', removeDollar) => {
    if ((!priceString && parseInt(priceString) !== 0) || (priceString.toString() || '').length <= 0) {
        return fallback;
    }
    //Check if price is string, if it is we need to check for '$'
    var price;
    if (typeof priceString === 'string') {
        if (priceString.charAt(0) === '$') {
            price = Number(priceString.slice(1));
        } else {
            price = Number(priceString);
        }
    } else {
        price = Number(priceString);
    }

    priceString = price.toFixed(2);

    var parts = priceString.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (!removeDollar) {
        return '$' + parts.join('.');
    } else return parts.join('.');
};

var convertFromCurrency = (priceString, fallback = 0) => {
    if (priceString === 'N/A' || !priceString || (priceString.toString() || '').length <= 0) {
        //Added another condition if Price is 'N/A' return 0
        return fallback;
    }

    priceString = priceString.toString().replace('$', '');
    priceString = priceString.replace(/,/g, '');
    var price = Number(priceString);

    return price.toFixed(2);
};

var canModifyPo = (po) => {
    return !(
        po.Status === Statuses.CANCELLED_BY_CUSTOMER ||
        po.Status === Statuses.CANCELLED_BY_DRISCOLLS ||
        po.Status === Statuses.CANCELLED ||
        po.Status === Statuses.INVOICED ||
        po.Status === Statuses.IN_TRANSIT ||
        po.DriscollsOrderLock === 1
    );
};

var isCurrencyFormat = (value) => {
    if (typeof value !== 'string') {
        return false;
    }

    if (value.charAt(0) !== '$') {
        return false;
    }

    var parts = value.split('.');

    if (!(parts.length === 2 && parts[1].length === 2)) {
        return false;
    }

    return true;
};

var isQuote = (quoteString) => {
    // Returns true if Quote, false if PriceList.
    if (quoteString.includes('SQ')) {
        return true;
    } else {
        return false;
    }
};

var convertToMilliSeconds = (date) => {
    return new Date(date).setHours(0, 0, 0, 0);
};

var forceUpdatePoList = (timeout = 0) => {
    setTimeout(() => {
        var el = document.querySelector('.react-grid-Canvas');
        if (!el) {
            return;
        }
        var left = el.scrollLeft;
        var top = el.scrollTop;
        el.scroll(left + 1, top + 1);
        el.scroll(left - 1, top - 1);
        el.scroll(left, top);
    }, timeout);
};

const DataUtilities = {
    ConvertToCurrency: convertToCurrency,
    ConvertFromCurrency: convertFromCurrency,
    CanModifyPo: canModifyPo,
    IsCurrencyFormat: isCurrencyFormat,
    IsQuote: isQuote,
    ConvertToMilliSeconds: convertToMilliSeconds,
    ForceUpdatePoList: forceUpdatePoList
};

export default DataUtilities;
