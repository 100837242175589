class POStatus {
    static CANCELLED_BY_DRISCOLLS = 'CancelledD';
    static ADJUSTED_BY_DRISCOLLS = 'AdjustedD';
    static ADJUSTED_BY_CUSTOMER = 'AdjustedC';
    static CANCELLED_BY_CUSTOMER = 'CancelledC';
    static OPEN = 'Open';
    static CONFIRMED = 'Confirmed';
    static IN_TRANSIT = 'In transit';
    static INVOICED = 'Invoiced';
    static CANCELLED = 'Cancelled';
    static REJECTED = 'Rejected'; //All for mule !
}

export default POStatus;
