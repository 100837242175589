import { Middleware } from 'one-ring';

const StateInfoMapping = [
    Middleware.CreateMapping('poolWeek', 'poolWeek'),
    Middleware.CreateMapping('empResp', 'empResp'),
    Middleware.CreateMapping('invoiceName', 'invoiceName'),
    Middleware.CreateMapping('OpenPOs', 'OpenPos'),
    Middleware.CreateMapping('CustomerChanges', 'CustomerChanges'),
    Middleware.CreateMapping('UnmappedPOs', 'UnmappedPos'),
    Middleware.CreateMapping('SalesOrders', 'SalesOrders'),
    Middleware.CreateMapping('name', 'name'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('StateInfo', 'GET')) {
        Middleware.RegisterMapping('StateInfo', 'GET', StateInfoMapping, true);
    }
}

const StateInfo = {
    ConfigureMiddleware
};

export default StateInfo;
