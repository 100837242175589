import { Middleware } from 'one-ring';

const StatusMappings = [
    Middleware.CreateMapping('Type', 'Type'),
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('LookupType', 'LookupType'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Meaning', 'Meaning'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateMapping('DisplaySequence', 'DisplaySequence'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('Attribute1', 'Attribute1'),
    Middleware.CreateMapping('Attribute2', 'Attribute2'),
    Middleware.CreateMapping('Attribute3', 'Attribute3'),
    Middleware.CreateMapping('Attribute4', 'Attribute4'),
    Middleware.CreateMapping('Attribute5', 'Attribute5'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Status', 'GET')) {
        Middleware.RegisterMapping('Status', 'GET', StatusMappings);
        Middleware.RegisterMapping('Status', 'POST', StatusMappings);
    }
}

const Status = {
    ConfigureMiddleware
};

export default Status;
