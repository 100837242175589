import { Middleware } from 'one-ring';

const PoLineItemsMappings = [
    Middleware.CreateMapping('PoHeaderId', 'PoHeaderId'),
    Middleware.CreateMapping('Index', 'Index'),
    Middleware.CreateMapping('RecordFetchedTime', 'recordFetchedTime'),
    Middleware.CreateMapping('POLineId', 'POLineId'),
    Middleware.CreateMapping('PoNumber', 'PONumber'),
    Middleware.CreateMapping('ProductName', 'Description'),
    Middleware.CreateMapping('ItemId', 'DriscollsItemNbr'),
    Middleware.CreateMapping('CustomerItemId', 'CustomerItemID'),
    Middleware.CreateMapping('CustomerItemDescription', 'CustomerItemDescription'),
    Middleware.CreateMapping('ArrivalDate', 'ArrivalDate'),
    Middleware.CreateMapping('ExpectedDate', 'DeliveryDate'),
    Middleware.CreateMapping('OriginalQuantity', 'DriscollsQuantity'),
    Middleware.CreateMapping('OriginalOrderQuantity', 'OriginalQuantity'),
    Middleware.CreateMapping('Quantity', 'QuantityOrdered'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('RemainingVolume', 'RemainingVolume'),
    Middleware.CreateMapping('ShippedQuantity', 'ShippedQuantity'),
    Middleware.CreateMapping('ShippedDate', 'ShippedDate'),
    Middleware.CreateMapping('CustomerRequestedPrice', 'CustomerRequestedPrice'),
    Middleware.CreateMapping('LoadedPrice', 'LoadedPrice'),
    Middleware.CreateMapping('UnitPrice', 'UnitPrice'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('UOM', 'UoM'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('ItemStatus', 'ItemStatus'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('Warehouse', 'WareHouseNbr'),
    Middleware.CreateMapping('NameIdentificationCode', 'NameIdentificationCode'),
    Middleware.CreateMapping('DeliveryTerms', 'DeliveryTerm'),
    Middleware.CreateMapping('PaymentTerms', 'PaymentTerms'),
    Middleware.CreateMapping('SoNumber', 'SalesOrderNbr'),
    Middleware.CreateMapping('SelectedQuoteNumber', 'QuoteNbr'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('DeliveryRequested', 'DeliveryRequestedDate'),
    Middleware.CreateMapping('ShipmentRequested', 'ShipmentRequestedDate'),
    Middleware.CreateMapping('ModeOfDelivery', 'DlvMode'),
    Middleware.CreateMapping('ShipTo', 'Shiptonumber'),
    Middleware.CreateMapping('SalesTakerId', 'SalesTakerId'),
    Middleware.CreateMapping('EmployeeResponsibleId', 'EmployeeResponsibleId'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('Channel', 'Channel'),
    Middleware.CreateMapping('MaxAssignedId', 'MaxAssignedId'),
    Middleware.CreateMapping('BerryTypeId', 'BerryTypeId'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('SalesType', 'SalesType'),
    Middleware.CreateMapping('Substitution', 'Substitution'),
    Middleware.CreateMapping('Prorated', 'Prorated'),
    Middleware.CreateMapping('Rebate', 'Rebate'),
    Middleware.CreateMapping('Date', 'Date'),
    Middleware.CreateArrayMapping('Results', 'POLineItems'),
    Middleware.CreateObjectMapping('PoHeader', 'POheader'),
    Middleware.CreateObjectMapping('LinkedLines', 'LinkedLines'),
    Middleware.CreateMapping('PalletCount', 'PalletCount')
];

const StagingPoLineItemsMappings = [
    //TODO: Add Index Mapping for PO Line Item Id
    Middleware.CreateMapping('PoHeaderId', 'PoStageId'),
    Middleware.CreateMapping('Index', 'Index'),
    Middleware.CreateMapping('POStageLineId', 'POStageLineId'),
    Middleware.CreateMapping('PoNumber', 'PONumber'),
    Middleware.CreateMapping('ItemId', 'DriscollsItemNbr'),
    Middleware.CreateMapping('CustomerItemId', 'BuyerProdSvcId'),
    Middleware.CreateMapping('ProductName', 'ItemDescription'),
    Middleware.CreateMapping('ArrivalDate', 'ArrivalDate'),
    Middleware.CreateMapping('ExpectedDate', 'DeliveryDate'),
    Middleware.CreateMapping('OriginalQuantity', 'DriscollsQuantity'),
    Middleware.CreateMapping('OriginalOrderQuantity', 'OriginalQuantity'),
    Middleware.CreateMapping('Quantity', 'QuantityOrdered'),
    Middleware.CreateMapping('RemainingVolume', 'RemainingVolume'),
    Middleware.CreateMapping('ShippedQuantity', 'ShippedQuantity'),
    Middleware.CreateMapping('ShippedDate', 'ShippedDate'),
    Middleware.CreateMapping('CustomerRequestedPrice', 'CustomerRequestedPrice'),
    Middleware.CreateMapping('UnitPrice', 'UnitPrice'),
    Middleware.CreateMapping('LoadedPrice', 'LoadedPrice'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('UOM', 'UoM'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('ItemStatus', 'ItemStatus'),
    Middleware.CreateMapping('AssignedId', 'AssignedId'),
    Middleware.CreateMapping('DeliveryRequested', 'DeliveryRequestedDate'),
    Middleware.CreateMapping('PaymentTerms', 'PaymentTerms'),
    Middleware.CreateMapping('PoStageId', 'POStageId'),
    Middleware.CreateMapping('ShipmentRequested', 'ShipmentRequestedDate'),
    Middleware.CreateMapping('Channel', 'Channel'),
    Middleware.CreateMapping('TransControlNbr', 'TransControlNbr'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('EmployeeResponsibleId', 'EmployeeResponsibleId'),
    Middleware.CreateMapping('Warehouse', 'WareHouseNbr'),
    Middleware.CreateMapping('NameIdentificationCode', 'NameIdentificationCode'),
    Middleware.CreateMapping('DeliveryTerms', 'DeliveryTerm'),
    Middleware.CreateMapping('FSD', 'FSD'),
    Middleware.CreateMapping('ChangeType', 'ChangeType'),
    Middleware.CreateMapping('ShipTo', 'ShipToNumber'),
    Middleware.CreateMapping('UserAction', 'UserAction'),
    Middleware.CreateMapping('LastUpdatedDateTime', 'LastUpdatedDateTime'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('OBO', 'OBO'),
    Middleware.CreateMapping('ModeOfDelivery', 'DlvMode'),
    Middleware.CreateMapping('SalesTakerId', 'SalesTakerId'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('BerryTypeId', 'BerryTypeId'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('SalesType', 'SalesType'),
    Middleware.CreateMapping('Substitution', 'Substitution'),
    Middleware.CreateMapping('Prorated', 'Prorated'),
    Middleware.CreateMapping('Rebate', 'Rebate'),
    Middleware.CreateMapping('CustomerItemDescription', 'ItemDescription'),
    Middleware.CreateObjectMapping('LinkedLines', 'LinkedLines'),
    Middleware.CreateMapping('MaxAssignedId', 'MaxAssignedId'),
    Middleware.CreateArrayMapping('Results', 'LineItems'),
    Middleware.CreateObjectMapping('PoHeader', 'Header'),
    Middleware.CreateObjectMapping('Date', 'Date'),
    Middleware.CreateMapping('PalletCount', 'PalletCount'),
    Middleware.CreateMapping('Checked', 'Checked'),
    Middleware.CreateMapping('POAssignedId', 'POAssignedId'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PoLineItems', 'GET')) {
        Middleware.RegisterMapping('PoLineItems', 'GET', PoLineItemsMappings);
        Middleware.RegisterMapping('PoLineItems', 'POST', PoLineItemsMappings);
    }

    if (!Middleware.CheckMappingExists('StagingPoLineItems', 'GET')) {
        Middleware.RegisterMapping('StagingPoLineItems', 'GET', StagingPoLineItemsMappings);
        Middleware.RegisterMapping('StagingPoLineItems', 'POST', StagingPoLineItemsMappings);
    }
}

const PoLineItems = {
    ConfigureMiddleware
};

export default PoLineItems;
