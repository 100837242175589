import { SET_PAYMENT_FILTERS, SET_ALL_PAYMENT_FILTERS, CLEAR_PAYMENT_FILTERS, CLEAR_ONE_PAYMENT_FILTER } from 'actions/PaymentListActions';
import FilterUtilities from '../data/FilterUtilities';

const initialState = {
    selectedFilters: FilterUtilities.paymentFilters,
    gridColumns: FilterUtilities.paymentGridColumns,
    defaultGridColumns: FilterUtilities.DefaultGridColumns,
    showFilterControl: false,
    showColumnBar: false,
    showGridSettings: false,
    filterResult: []
};

const paymentsFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAYMENT_FILTERS:
            if (
                state.selectedFilters[action.payload.name] == null ||
                state.selectedFilters[action.payload.name].value !== action.payload.option.value ||
                state.selectedFilters[action.payload.name].isSet !== action.payload.option.isSet
            ) {
                return {
                    ...state,
                    selectedFilters: {
                        ...state.selectedFilters,
                        [action.payload.name]: action.payload.option
                    }
                };
            }
            return state;
        case SET_ALL_PAYMENT_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload.filters
            };
        case CLEAR_PAYMENT_FILTERS:
            return {
                ...state,
                selectedFilters: FilterUtilities.EmptyFilters
            };
        case CLEAR_ONE_PAYMENT_FILTER:
            var selectedFilters = {};
            Object.entries(state.selectedFilters).forEach((filter) => {
                if (state.selectedFilters[action.payload.filter].index < filter[1].index) {
                    selectedFilters[filter[0]] = { ...filter[1], index: filter[1].index - 1 };
                } else {
                    selectedFilters[filter[0]] = filter[1];
                }
            });

            return {
                ...state,
                selectedFilters: {
                    ...selectedFilters,
                    [action.payload.filter]: FilterUtilities.EmptyPaymentFilters[action.payload.filter]
                }
            };
        default:
            return state;
    }
};

export default paymentsFilterReducer;
